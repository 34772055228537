import React from 'react'
import Titles from '../components/Titles'

const TitlesPage = () => {
    return (
        <React.Fragment>
            <Titles />
        </React.Fragment>
    )
}

export default TitlesPage