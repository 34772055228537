import React, { createContext, useState, useContext } from 'react';
import api from '../apis/api.js'

export const PeopleBuilderContext = createContext();

export const PeopleBuilderProvider = ({ children }) => {
    const [allPeopleBuilder, setAllPeopleBuilder] = useState([])
    const userData = JSON.parse(localStorage.getItem('user'))
    const [filterType, setFilterType] = useState('')

    const getPeopleBuilders = async () => {

        try {
            const responseUserPeopleBuilder = await api.get(`/people-builders`)
            setAllPeopleBuilder(responseUserPeopleBuilder.data)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <PeopleBuilderContext.Provider value={{ allPeopleBuilder, getPeopleBuilders, filterType, setFilterType }}>
            {children}
        </PeopleBuilderContext.Provider>
    );
};

