import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

const EmployeeDialogSuspend = ({ open, handleClose, suspendUser, employee }) => {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are about to suspend {employee.full_name} accounts.
                    Are you sure you want to do this?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={suspendUser}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EmployeeDialogSuspend