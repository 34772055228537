import React, { useEffect, useState, useContext } from 'react'
import { Stack, Button, IconButton, Tooltip, Box, CircularProgress } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import { readyForApproval } from '../apis/sendEmailsAPI'
import { SnackbarContext } from '../context/SnackbarContext';

const SendEmailReadyForApproval = ({ peopleBuilder }) => {
    const { openAlert } = useContext(SnackbarContext)
    const [loading, setLoading] = useState(false)
    const [coachNotified, setCoachNotified] = useState(false)

    const handleClick = async () => {
        setLoading(true)
        const messageResponse = await readyForApproval(peopleBuilder)
        if (messageResponse.status === 200) {
            setCoachNotified(true)
            localStorage.setItem('coachNotified', true)
            openAlert('Coach has been notified', 'success')
        } else {
            openAlert('Oops! Something went wrong', 'error')
        }
        setLoading(false)
    }

    useEffect(() => {
        setCoachNotified(localStorage.getItem('coachNotified'))
    }, [coachNotified])

    return (
        <Box sx={{ m: 1, position: 'relative' }}>
            {!coachNotified && (
                <Stack direction='row' sx={{ justifyContent: 'right' }}>
                    <Tooltip title='Send notification to coach about goals ready for approval' placement='top'>
                        <Button onClick={handleClick} disabled={loading} sx={{ fontSize: 10 }} >
                            <EmailIcon sx={{ fontSize: 18, marginRight: 1 }} />
                            Notify coach
                        </Button>
                    </Tooltip>
                </Stack >
            )}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                    }}
                />
            )}
        </Box>
    )
}

export default SendEmailReadyForApproval