import api from './api.js'
const token = localStorage.getItem('token')

export const saveNewDepartmentAPI = async (newDepartment) => {

    try {
        const response = await api.post(`/departments`, { department: newDepartment })
        return response.data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getDepartmentAPI = async () => {

    try {

        const response = await api.get(`/departments`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
    }
}

