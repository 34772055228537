import api from "./api.js";
const token = localStorage.getItem('token')

export const createTitle = async (newTitle) => {
    const token = localStorage.getItem('token')

    try {
        const res = await api.post(`/titles/`, { title: newTitle })
        return res.data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getTitles = async () => {
    try {
        const response = await api.get(`/titles`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        return e
    }


}