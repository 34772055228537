import {
    Card, Fade, FormControl, TextField, CardContent, Container, Snackbar, Alert,
    Table, TableBody, TableCell, TableContainer, Button, Stack,
    TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    IconButton
} from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import globalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import BackdropLoader from './common/BackdropLoader.jsx'
import api from '../apis/api.js'
import { EmployeesContext } from "../context/UsersContext.js";
import SearchField from "./common/SearchField.jsx";
import { SnackbarContext } from "../context/SnackbarContext.js";

const Titles = () => {
    const { fetchUsers } = useContext(EmployeesContext)
    const { openAlert } = useContext(SnackbarContext)
    const [titles, setTitles] = useState([])
    const [allTitles, setAllTitles] = useState([])
    const token = localStorage.getItem('token')
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [title, setTitle] = useState([])
    const [titleForms, setTitleForms] = useState(false)
    const [editingRowId, setEditingRowId] = useState(null);
    const [tempTextField, setTempTextField] = useState('')
    const [iconVisible, setIconsVisible] = useState(false)
    const [openDeleteTitle, setOpenDeleteTitle] = useState(false)
    const [titleToDelete, setTitleToDelete] = useState({
        title: '',
        id: ''
    })
    const [search, setSearch] = useState('')

    const handleCloseDialog = () => {
        setOpenDeleteTitle(false)
    }

    const searchingTitles = useCallback((search) => {
        setTitles(allTitles.filter(title => title.title.toLowerCase().includes(search.toLowerCase())))
    }, [search, titles])

    const getTitles = useCallback(async () => {
        try {

            api.get(`/titles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                if (res.status === 200) {
                    var titles = res.data
                    titles.sort((a, b) => a.title.localeCompare(b.title))
                    setTitles(titles)
                    setAllTitles(titles)
                } else {
                    setTitles([])
                }
                setOpenBackdrop(false)
            }).catch((error) => {
                setOpenBackdrop(false)
                console.log(error)
            })

        } catch (e) {
            setOpenBackdrop(false)
            console.log(e)
        }
    }, [token])

    const saveNewTitle = async (newTitle) => {

        setOpenBackdrop(true)
        try {
            api.post(`/titles/`, { title: newTitle }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async res => {
                if (res.status === 200) {
                    openAlert('Title created sucessful', 'success')
                    setOpenBackdrop(false)
                    getTitles()
                    fetchUsers()
                }
            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    const deleteTitle = useCallback(async () => {
        setOpenDeleteTitle(false)
        setOpenBackdrop(true)
        const id = titleToDelete.id

        try {
            api.delete(`/titles/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async res => {
                openAlert(res.data.message, res.data.status === 200 ? 'success' : 'error')
                setOpenBackdrop(false)
                getTitles()
                fetchUsers()
            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    }, [titleToDelete.id, token, getTitles, fetchUsers])

    const updateTitle = async (id, newTitle) => {
        setOpenBackdrop(true)
        try {
            api.put(`/titles/${id}`, { title: newTitle }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async res => {
                if (res.status === 200) {
                    openAlert('Title updated sucessful', 'success')
                    setOpenBackdrop(false)
                    setTitle(newTitle)
                    fetchUsers()
                }
            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    useEffect(() => {
        getTitles()
    }, [getTitles])

    useEffect(() => {
        searchingTitles(search)
    }, [search])

    return (
        <Container sx={{mt: 10}}>
            <Card className={globalStyles.card}>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <CardContent>
                    <Stack mb={1} direction="row" justifyContent='space-between' >
                        <SearchField setSearch={setSearch} />
                        <Button
                            onClick={() => {
                                setEditingRowId(null)
                                setTitleForms(!titleForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >Add New</Button>
                    </Stack>
                    {titleForms && (
                        <Fade in={titleForms} timeout={200}>
                            <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                <CardContent>
                                    <FormControl fullWidth sx={{ m: 0 }} >
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                size="small"
                                                sx={{ width: '30%' }}
                                                id="outlined-basic"
                                                label="Title Name"
                                                variant="outlined"
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                            <Button
                                                onClick={() => {
                                                    setTitleForms(false)
                                                    saveNewTitle(title)
                                                }}
                                                variant="outlined"
                                                sx={{ width: '10%' }}
                                            >Save</Button>
                                        </Stack>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Fade>
                    )}
                    <TableContainer sx={{ boxShadow: 3 }} className={globalStyles.tableContainer}>
                        <Table stickyHeader className={globalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Title</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {titles.map(title => (
                                    <TableRow
                                        hover
                                        sx={{ height: 70 }}
                                        className={globalStyles.cursor_pointer}
                                        key={title.id}
                                    >
                                        <TableCell>
                                            {editingRowId === title.id ? (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    value={tempTextField}
                                                    onChange={(e) => setTempTextField(e.target.value)}
                                                    // onBlur={() => setEditingRowId(null)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            updateTitle(title.id, tempTextField);
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                title.title
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 200 }} >
                                            {(editingRowId !== title.id) && (
                                                <Stack direction='row' spacing={1}>
                                                    {/* <Fade in={focusedRow === title.id} timeout={500}> */}
                                                    <IconButton
                                                        onClick={() => {
                                                            setIconsVisible('hidden')
                                                            setTitleForms(false)
                                                            setEditingRowId(title.id);
                                                            setTempTextField(title.title);
                                                        }}
                                                        size="small" >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenDeleteTitle(true)
                                                            setTitleToDelete({
                                                                title: title.title,
                                                                id: title.id
                                                            })
                                                            // deleteTitle(title.id)
                                                        }}
                                                        size="small">
                                                        <DeleteIcon
                                                        />
                                                    </IconButton>
                                                    {/* </Fade> */}
                                                </Stack>
                                            )}
                                            {editingRowId === title.id && (
                                                <Stack direction='row'>
                                                    <IconButton onClick={() => {
                                                        updateTitle(title.id, tempTextField)
                                                    }}
                                                        size="small">
                                                        <SaveIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => setEditingRowId(null)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openDeleteTitle}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {titleToDelete.title}.
                        All employees with this title will have an empty field.
                        Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={deleteTitle} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container >
    )
}

export default Titles