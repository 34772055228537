import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import {
    Button, Card, CardContent, Container, Stack,
    Autocomplete, TextField, Dialog, DialogContent,
    DialogContentText, DialogActions, Tooltip, Checkbox, FormGroup, FormControlLabel, FormLabel
} from '@mui/material';
import { EmployeesContext } from '../context/UsersContext.js';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../apis/api.js'
import { createTitle, getTitles } from '../apis/titlesAPI.js';
import { getDepartmentAPI, saveNewDepartmentAPI } from '../apis/departmentsAPI.js';
import BackdropLoader from './common/BackdropLoader.jsx'
import { SnackbarContext } from '../context/SnackbarContext.js';
import { LoaderContext } from '../context/LoaderContext.js';
import EmployeeInfo from './EmployeeInfo.jsx';
import EmployeeDialogSuspend from './EmployeeDialogSuspend.jsx';
import NewTitleDialog from './NewTitleDialog.jsx';
import NewDepartmentDialog from './NewDepartmentDialog.jsx';

const Profile = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const { openAlert } = useContext(SnackbarContext)
    const { loading, setLoading } = useContext(LoaderContext)
    const { userId } = useParams()
    const [coachChecked, setCoachChecked] = useState(false)
    const [open, setOpen] = useState(false);
    const [openChangePhoto, setOpenChangePhoto] = useState(false);
    const [openDialogNewDepartment, setOpenDialogNewDepartment] = useState(false)
    const [openDialogNewTitle, setOpenDialogNewTitle] = useState(false)
    const [employee, setEmployee] = useState([])
    const [selectedFields, setSelectedFields] = useState({
        department: null,
        departmentId: null,
        title: null,
        titleId: null,
        coach: null,
        manager: null,
        workLocation: null,
        workLocationId: null,
        phoneType: '',
        phone: ''
    })
    const [fileName, setFileName] = useState('')
    const [image, setImage] = useState('')
    const [coachList, setCoachList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [titles, setTitles] = useState([{ label: 'New Title', id: 'new' }])
    const [departments, setDepartments] = useState([{ label: 'New Department', id: 'new' }])
    const [workLocations, setWorkLocations] = useState([])
    const [disabledField, setDisabledField] = useState(false)
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const fileInputRef = useRef(null)
    const [disabledCoach, setDisabledCoach] = useState(coachChecked)
    
    const handleClick = () => {
        fileInputRef.current.click();
    }
    var coachs = []
    var coachsValues = []
    var managers = []
    var managersValues = []

    const bringTitles = async () => {
        const titles = await getTitles()
        titles?.sort((a, b) => a.title.localeCompare(b.title))
        const titleOptions = titles?.map((title) => ({ label: title.title, id: title.id }))
        setTitles([{ label: 'New Title', id: 'new' }, ...titleOptions])
    }

    const getDepartments = useCallback(async () => {
        const departments = await getDepartmentAPI()
        departments?.sort((a, b) => a.department.localeCompare(b.department))
        const departmentOptions = departments?.map((department) => ({ label: department.department, id: department.id }))
        setDepartments([{ label: 'New Department', id: 'new' }, ...departmentOptions])
    })

    const updateTitleVcard = async () => {
        const data = { title: selectedFields.title, user: employee.full_name }

        try {
            const response = await api.post('/vcard/updateTitle', data)
        } catch (error) {
            console.log(error)
        }
    }

    async function updateVcardPhoto() {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('user', JSON.stringify(employee))

        try {
            const response = await api.post('/vcard/updatePhoto', formData);
            const result = response.data
            openAlert(result.message, 'success')
        } catch (error) {
            console.error('Error:', error);
            openAlert(error.response.data.message, 'error')
        }
    }

    const getWorkLocations = useCallback(async () => {
        setWorkLocations([])
        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/work-locations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var workLocations = await res.json()
                workLocations.sort((a, b) => a.building_name.localeCompare(b.building_name))
                workLocations.map((location) => {
                    setWorkLocations((prevStates) => ([...prevStates, { label: location.building_name, id: location.id }]))
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const findManagerEmail = (directManagerEmail) => {
        const managerEmail = employees.find(employee => employee.email === directManagerEmail)
        return managerEmail?.full_name
    }

    const handleUpdateEmployee = useCallback(async () => {
        setLoading(true)

        const updatedEmployeeData = {
            userId: userId,
            department_id: selectedFields?.departmentId ?? '',
            departmentText: selectedFields?.department ?? '',
            title_id: selectedFields?.titleId ?? '',
            titleText: selectedFields?.title ?? '',
            coach: selectedFields?.coach ?? '',
            direct_manager: selectedFields?.manager ?? '',
            work_location_id: selectedFields?.workLocationId ?? '',
            work_location_text: selectedFields?.workLocation ?? '',
            etro_type_phone: selectedFields?.phoneType ?? '' === 'ETRO Phone' ? 'work' : 'mobile',
            phone: selectedFields?.phone ?? ''
        }

        const employeeSelected = employees.find(employee => employee.id === userId)
        const coachSelected = employees.find(employee => employee.full_name === selectedFields?.coach)

        const peopleBuilderData = {
            coach_id: coachSelected?.id
        }

        try {
            const response = await api.put(`/user/update/${employeeSelected.id}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                try {
                    const pbResponse = await api.patch(`/people-builders/${employeeSelected.id}`, peopleBuilderData)
                    updateTitleVcard()
                } catch (error) {
                    console.log(error)
                }
            }

            if (response.status === 200) {
                openAlert('Employee updated successful', 'success')
                fetchUsers()
                navigate('/employee')
            } else {
                console.error('Something was wrong:', response);
                openAlert(response.message, 'error')
            }

            setLoading(false)

        } catch (error) {
            console.error('Something was wrong:', error.message);
            openAlert(error.message, 'error')
            setLoading(false)
        }

        // Updating Google Admin
        try {
            const response = await api.put(`/user/update/google/${userId}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        } catch (error) {
            console.error('Something was wrong:', error);
        }

    }, [selectedFields, employees])

    const employeeFitered = useCallback(() => {

        employees.map(employee => {

            if (!employee.etro_team) {
                return
            }

            if (!coachsValues.includes(employee.full_name) && employee.full_name !== '') {
                coachsValues.push(employee.full_name)
                coachs.push({ label: employee.full_name, id: employee.id })
            }

            if (!managersValues.includes(employee.full_name) && employee.full_name) {
                managersValues.push(employee.full_name)
                managers.push({ label: employee.email, id: employee.id })
            }

        })

        coachs.sort((a, b) => a?.label?.localeCompare(b?.label))
        workLocations.sort((a, b) => a?.label?.localeCompare(b?.label))

        setCoachList(coachs)
        setManagerList(managers)

    }, [employees])

    const updateSelectedField = (field, value) => {
        setSelectedFields(prevState => ({ ...prevState, [field]: value }));
    };

    const fetchAndFilterEmployeeInfo = useCallback(async () => {
        const employee = employees.find(emp => emp.id === userId);
        const employeeFixed = employees.find(emp => emp.id === userId);

        if (employee) {
            updateSelectedField('departmentId', employee.departmentId);
            updateSelectedField('department', employeeFixed?.department?.department ?? null);
            updateSelectedField('titleId', employee.titleId);
            updateSelectedField('title', employeeFixed?.title?.title ?? null);
            updateSelectedField('coach', employee.coach);
            updateSelectedField('manager', employee.direct_manager);
            updateSelectedField('workLocationId', employee.work_location_id);
            updateSelectedField('workLocation', employeeFixed?.work_location?.building_name ?? null);
            updateSelectedField('phoneType', employee.etro_type_phone === 'work' ? 'ETRO Phone' : 'Personal Phone');
            updateSelectedField('phone', employee.phone);
        }
        setLoading(false)
    }, [employees, userId]);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClickOpenDialogNewDepartment = () => {
        setOpenDialogNewDepartment(true)
    }

    const handleCloseDialog = () => {
        setOpen(false);
        setOpenChangePhoto(false);
        setOpenDialogNewDepartment(false);
        setOpenDialogNewTitle(false);
        setFileName('')
    }

    const suspendUser = useCallback(async () => {
        setOpen(false)
        setLoading(true)

        try {
            const response = await api.put(`/user/suspend/${employee.id}`, { suspended: !employee.suspended })

            if (response.status === 200) {
                setEmployee(response.data[0])
                fetchUsers()
                openAlert(`User ${employee.suspended ? 'Activated' : 'Suspended'} successful`, 'success')
            } else {
                openAlert('Oops! Something went wrong', 'error')
            }
            setLoading(false)

        } catch (error) {
            openAlert('Oops! Something went wrong')
            console.error('Something was wrong:', error);
            setLoading(false)
        }

    }, [employee])

    const updateProfilePhoto = async () => {
        setLoading(true)
        setOpenChangePhoto(false)
        await changeSlackPhoto()
        await changeGooglePhoto()
        await updateVcardPhoto()
        await fetchUsers()
        setLoading(false)
    }

    const changeSlackPhoto = async (e) => {

        const formData = new FormData()
        formData.append('image', image)

        try {
            const response = await api.post(`/user/update-slack-photo/${employee.id}`, formData)

            if (response.status === 200) {
                openAlert('Slack Profile Photo was updated Successfully', 'success')
            } else {
                openAlert('Slack Profile Photo was not updated Successfully', 'error')
            }

            return response
        } catch (error) {
            console.log(error)
            openAlert(error.response.data.message, 'error')
        }
    }

    const changeGooglePhoto = async (e) => {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('userData', JSON.stringify(employee))

        try {
            const response = await api.post('/change-google-photo', formData)
            if (response.status === 200) {
                openAlert('Google Profile Photo was updated Successfully', 'success')
            } else {
                openAlert('Google Profile Photo was not updated Successfully', 'error')
            }

            return response
        } catch (error) {
            openAlert(error.message, 'error')
        }
    }

    useEffect(() => {
        employeeFitered()
        const employee = employees.find(employee => employee.id === userId);
        if (employee) {
            setEmployee(employee)

            if (employee.is_admin || employee.is_delegated_admin) {
                setDisabledField(true)
                setDisabledCoach(true)
            }
        }

    }, [employeeFitered, userId, setSelectedFields, employees])

    useEffect(() => {
        fetchAndFilterEmployeeInfo()
    }, [fetchAndFilterEmployeeInfo])

    useEffect(() => {
        fetchUsers()
        bringTitles()
        getDepartments()
        getWorkLocations()
    }, [])

    useEffect(() => {
        const coach = selectedFields.coach
        const managerEmail = selectedFields.manager
        if (coach && coach === findManagerEmail(managerEmail)) {
            setCoachChecked(true)
            setDisabledCoach(true)
        }
    }, [selectedFields])

    const handleReturnToListUser = useCallback(() => {
        navigate('/employee')
    })

    return (
        <Container sx={{mt: 10}} maxWidth="xl" >
            <>
                <BackdropLoader openBackdrop={loading} />
                <Card sx={{ mt: 4 }}>
                    <CardContent>
                        <Stack spacing={2}>
                            <EmployeeInfo setOpenChangePhoto={setOpenChangePhoto} employee={employee} />
                            <Stack spacing={2} direction='row' alignItems={'center'}>
                                {departments.length > 0 && (
                                    <Autocomplete id="department"
                                        disabled={disabledField}
                                        options={departments}
                                        sx={{ width: '50%' }}
                                        value={departments.find(option => option.label === selectedFields.department) || null}

                                        onChange={(event, newValue) => {
                                            if (newValue?.id === 'new') {
                                                handleClickOpenDialogNewDepartment()
                                            } else {
                                                updateSelectedField('department', newValue?.label);
                                                updateSelectedField('departmentId', newValue?.id);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Department" variant="standard" />}

                                    />
                                )}
                                {titles.length > 0 && (
                                    <Autocomplete
                                        id="title"
                                        disabled={disabledField}
                                        options={titles}
                                        sx={{ width: '50%' }}
                                        value={titles.find(option => option.label === selectedFields.title) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue?.id === 'new') {
                                                setOpenDialogNewTitle(true)
                                            } else {
                                                updateSelectedField('title', newValue?.label)
                                                updateSelectedField('titleId', newValue?.id)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Title" variant="standard" />}
                                    />
                                )}
                            </Stack>
                            <Stack spacing={2} direction='row' alignItems={'center'}>
                                {managerList.length > 0 && (
                                    <Autocomplete id="manager"
                                        disabled={disabledField}
                                        options={managerList}
                                        variant="standard"
                                        sx={{ width: '40%' }}
                                        value={managerList.find(option => option.label === selectedFields.manager) || null}
                                        onChange={(event, newValue) => {
                                            updateSelectedField('manager', newValue?.label);

                                            if (coachChecked) {
                                                updateSelectedField('coach', findManagerEmail(newValue?.label))
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Direct Manager'
                                                variant="standard" />
                                        )}
                                    />
                                )}
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ width: '100%', '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                                        label='Coach is the same as direct manager'
                                        control={
                                            <Checkbox
                                                // size='small'
                                                disabled={disabledField}
                                                checked={coachChecked}
                                                onChange={(event) => {
                                                    setCoachChecked(event.target.checked)
                                                    setDisabledCoach(event.target.checked)
                                                    event.target.checked ? updateSelectedField('coach', findManagerEmail(selectedFields.manager)) : ''
                                                }}
                                            />}
                                    >
                                    </FormControlLabel>
                                </FormGroup>
                                {coachList.length > 0 && (
                                    <Autocomplete id="coach"
                                        disabled={disabledCoach}
                                        options={coachList}
                                        sx={{ width: '40%' }}
                                        value={coachList.find(option => option.label === selectedFields.coach) || null}
                                        onChange={(event, newValue) => {
                                            newValue ? updateSelectedField('coach', newValue?.label) : '';
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    {...params}
                                                    onKeyDown={(e) => {
                                                        !coachChecked ? '' : e.preventDefault()
                                                    }}
                                                    label={
                                                        <FormGroup>
                                                            <Stack direction='row' spacing={4}>
                                                                <FormLabel>Coach</FormLabel>
                                                            </Stack>
                                                        </FormGroup>
                                                    }
                                                    variant="standard"
                                                />
                                            </>
                                        )}
                                    />
                                )}
                            </Stack>

                            <Stack spacing={2} direction='row' alignItems={'center'}>
                                {workLocations.length > 0 && (
                                    <Autocomplete
                                        id="work-location"
                                        disabled={disabledField}
                                        options={[{ label: 'New Work Location', id: 'new' }, ...workLocations]}
                                        sx={{ width: '60%' }}
                                        value={workLocations.find(option => option.label === selectedFields.workLocation) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue?.id === 'new') {
                                                navigate('/work-locations');
                                            } else {
                                                updateSelectedField('workLocation', newValue?.label);
                                                updateSelectedField('workLocationId', newValue?.id);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Work Location" variant="standard" />}
                                    />
                                )}
                                <Autocomplete
                                    id="Phone-type"
                                    disabled={disabledField}
                                    options={['ETRO Phone', 'Personal Phone']}
                                    sx={{ width: '20%' }}
                                    value={selectedFields.phoneType || ''}
                                    onChange={(event, newValue) => {
                                        updateSelectedField('phoneType', newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Phone Type" variant="standard" />}
                                />
                                <TextField id="phone outlined-basic"
                                    disabled={disabledField}
                                    label='Phone'
                                    variant="standard"
                                    sx={{ width: '20%' }}
                                    value={selectedFields.phone}
                                    onChange={(event) => {
                                        updateSelectedField('phone', event.target.value);
                                    }}
                                />
                            </Stack>
                            <Stack direction="row" style={{ marginTop: 36 }} spacing={10} useFlexGap justifyContent="flex-end">
                                <Tooltip title="This action will suspend the User's ETRO accounts" placement="top">
                                    <Button
                                        disabled={disabledField}
                                        onClick={handleClickOpen}
                                        sx={{ backgroundColor: '#f44336' }}
                                        variant="contained"
                                    >
                                        {employee.suspended ? 'Activate' : 'Suspend'}
                                    </Button>
                                </Tooltip>

                                <Stack direction='row' spacing={2}>
                                    <Button
                                        onClick={() => { handleReturnToListUser() }}
                                        color='error'
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleUpdateEmployee}
                                        // onClick={updateTitleVcard}
                                        disabled={disabledField}
                                        color='success'
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Stack>

                        </Stack>
                    </CardContent>
                    {/* {loadingSave && <Loader />} */}
                </Card>
                <EmployeeDialogSuspend open={open} handleClose={handleCloseDialog} suspendUser={suspendUser} employee={employee} />
                <NewDepartmentDialog
                    openAlert={openAlert}
                    setLoading={setLoading}
                    setSelectedFields={setSelectedFields}
                    openDialogNewDepartment={openDialogNewDepartment}
                    handleCloseDialog={handleCloseDialog}
                    getDepartments={getDepartments}

                />
                <Dialog
                    open={openChangePhoto}
                    id='add-photo'
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Stack spacing={2}>
                                {fileName.length <= 0 ? (
                                    <Stack>
                                        <Button variant="contained" component="span" onClick={handleClick}>
                                            Upload File
                                        </Button>
                                    </Stack>
                                ) : (
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        value={fileName}
                                        variant="standard"
                                        disabled />
                                )
                                }
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={(e) => {
                                        setImage(e.target.files[0])
                                        setFileName(e.target.files[0].name)
                                    }}
                                    style={{ display: 'none' }}
                                />

                                <Stack spacing={0.5}>
                                    <span><b>Format:</b> JPG or PNG.</span>
                                    <span><b>Size:</b> Between 10 KB and 5 MB.</span>
                                    <span><b>Recommended resolution:</b> 720 px tall, 720 px wide.</span>
                                    <span><b>Minimum resolution:</b> 250 px tall, 250 px wide.</span>
                                    <span><b>Quality:</b> The photo should be in focus and well lit, and have no significant
                                        alterations or excessive use of filters.
                                        In other words, the image should represent reality.</span>
                                </Stack>
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseDialog}
                            variant="outlined"
                        >Cancel</Button>
                        {/* <form action={`${process.env.REACT_APP_BASE_URL}/auth/google`}> */}
                        <Button
                            type='submit'
                            onClick={() => {
                                updateProfilePhoto()
                            }}
                            variant="outlined"
                        >Confirm</Button>

                        {/* </form> */}

                    </DialogActions>
                </Dialog>
                <NewTitleDialog
                    openAlert={openAlert}
                    setLoading={setLoading}
                    setSelectedFields={setSelectedFields}
                    bringTitles={bringTitles}
                    setTitles={setTitles}
                    openDialogNewTitle={openDialogNewTitle}
                    handleCloseDialog={handleCloseDialog}
                />

            </>

        </Container >
    );
}

export default Profile;