import React, { useState } from 'react'
import { createContext } from 'react'
import api from '../apis/api'

export const GoalsContext = createContext()

export const GoalsProvider = ({ children }) => {

    const [goals, setGoals] = useState([])

    const getGoals = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await api.get('/goals')
                resolve(response.data)
                setGoals(response.data)
            } catch (error) {
                reject(error)
                console.log('goals context', error)
            }
        })
    }

    return (
        <GoalsContext.Provider value={{ getGoals, goals }} >
            {children}
        </GoalsContext.Provider>
    )

}
