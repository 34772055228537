import React from 'react'
export const SnackbarContext = React.createContext()

export const SnackbarProvider = ({ children }) => {
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = React.useState('')
    const [messageSnackbar, setMessageSnackbar] = React.useState('')

    const openAlert = (message, type) => {
        
        setOpenSnackbar(true)
        setMessageSnackbar(message)
        setSeverytypeSnackbar(type)
    }

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    return (
        <SnackbarContext.Provider value={{openSnackbar, severytypeSnackbar, messageSnackbar, openAlert, handleClose}}>
            {children}
        </SnackbarContext.Provider>
    )
}