import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js'

export default function MenuPeopleBuilder() {
    const { setFilterType, filterType, getPeopleBuilders } = useContext(PeopleBuilderContext)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isAdmin, setIsAdmin] = React.useState({})
    const user = JSON.parse(localStorage.getItem('user'))
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleFilterType = (type) => {
        setFilterType(type)
        localStorage.removeItem('playerIdClickedPB');
        setAnchorEl(null)
    }

    React.useEffect(() => {
        setIsAdmin(user?.is_admin)
    }, [])

    React.useEffect(() => {
        getPeopleBuilders()
    }, [filterType])

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: "inherit" }}
            >
                People Builder
                <ArrowDropDownIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    component={Link}
                    to={`/personal-people-builder/${user?.id}`}
                    onClick={() => handleFilterType('personal')}
                >
                    Personal Goals
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={`/team-people-builder/${user?.id}`}
                    onClick={() => handleFilterType('team')}
                >
                    Team Goals
                </MenuItem>
                {isAdmin && (
                    <MenuItem
                        component={Link}
                        to={`/company-people-builder/1`}
                        onClick={() => handleFilterType('all')}
                    >
                        Company Goals
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}