import { Box, Typography } from '@mui/material'
import React from 'react'

const SideBarOnGoalForm = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: '#212121',
                width: '25%',
                maxHeight: '85vh',
                color: 'white',
                padding: '10px',
                marginRight: 0,
                borderRadius: '0 5px 5px 0',
            }}
        >
            <div>
                <Typography variant='p'>The <b>SMART Goals</b> helps push you further, gives you a sense of direction, and helps you
                    organize and reach your goals.</Typography>
            </div>
            <div
                className="fix_texts"
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant='h2' style={{ margin: 0, fontWeight: 'bold' }}>S</Typography>
                <Typography variant='p' style={{ fontSize: '22px', fontWeight: 'bolder' }}>pecific</Typography>
            </div>
            <div
                className="fix_texts"
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant='h2' style={{ margin: 0, fontWeight: 'bold' }}>M</Typography>
                <Typography variant='p' style={{ fontSize: '22px', fontWeight: 'bolder' }}>easurable</Typography>
            </div>
            <div
                className="fix_texts"
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant='h2' style={{ margin: 0, fontWeight: 'bold' }}>A</Typography>
                <Typography variant='p' style={{ fontSize: '22px', fontWeight: 'bolder' }}>chievable</Typography>
            </div>
            <div
                className="fix_texts"
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant='h2' style={{ margin: 0, fontWeight: 'bold' }}>R</Typography>
                <Typography variant='p' style={{ fontSize: '22px', fontWeight: 'bolder' }}>ealistic</Typography>
            </div>
            <div
                className="fix_texts"
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant='h2' style={{ margin: 0, fontWeight: 'bold' }}>T</Typography>
                <Typography variant='p' style={{ fontSize: '22px', fontWeight: 'bolder' }}>imely</Typography>
            </div>
            <div className="fix_texts">
                <img style={{ width: '100%', margin: 'auto' }} src="https://iili.io/JEHmpQ1.png"
                    alt="" />
            </div>
        </Box >
    )
}

export default SideBarOnGoalForm