import React, { useEffect, useState } from 'react'
import {
    Card, TextField, Stack, Button, CardContent, InputLabel, FormControl, Select, MenuItem, FormHelperText
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import EmailEditor from './EmailEditor.jsx'
import api from '../apis/api'
import moment from 'moment'
import BackdropLoader from './common/BackdropLoader.jsx'
import { SnackbarContext } from '../context/SnackbarContext.js'

const NewPeopleBuilderSettings = (props) => {
    const [startDate, setStartDate] = useState(null)
    const { openAlert } = React.useContext(SnackbarContext)
    const {
        peopleBuilderId, setOpenPeopleBuilderForm,
        getPeopleBuilderSettings, peopleBuilderSettings
    } = props || null
    const [endDate, setEndDate] = useState(null)
    const [midYearDate, setMidYearDate] = useState('')
    const [endYearDate, setEndYearDate] = useState('')
    const [emailText, setEmailText] = useState('')
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [url, setUrl] = useState('')
    const [pbData, setPbData] = useState({
        start_date: undefined,
        end_date: undefined,
        mid_year_checkin: undefined,
        end_year_checkin: undefined,
        file_url: undefined,
        email_message: undefined,
        slack_message: undefined,
    })
    const [months] = useState([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ])
    const [monthsEndYearCheckin, setMonthsEndYearCheckin] = useState([])
    const [startDateEmpty, setStartDateEmpty] = useState({ error: false, errorText: '' })
    const [endDateEmpty, setEndDateEmpty] = useState({ error: false, errorText: '' })
    const [midYearEmpty, setMidYearEmpty] = useState({ error: false, errorText: '' })
    const [endYearEmpty, setEndYearEmpty] = useState({ error: false, errorText: '' })
    const [pdfEmpty, setPdfEmpty] = useState({ error: false, errorText: '' })

    const createPeopleBuilderSettings = async () => {
        let status = ''
        if (new Date() >= startDate && new Date() <= endDate) {
            status = 'active'
        } else if (startDate > new Date()) {
            status = 'next'
        } else {
            status = 'previous'
        }

        const data = {
            start_date: startDate,
            end_date: endDate,
            status: status,
            mid_year_checkin: pbData.mid_year_checkin,
            end_year_checkin: pbData.end_year_checkin,
            email_message: pbData.email_message,
            slack_message: pbData.slack_message,
            file_url: url
        }

        if (hasEmptyFields(data)) {
            return
        }

        setOpenBackdrop(true)

        const existingPb = peopleBuilderSettings.filter(pb => {
            const currYear = new Date(data.start_date).getUTCFullYear()
            const year = new Date(pb.start_date).getUTCFullYear()

            return year === currYear
        })

        if (existingPb.length) {
            openAlert(`This People Builder already exists, Year: ${new Date(data.start_date).getUTCFullYear()} `, 'error')
            setOpenBackdrop(false)
            return
        }

        try {
            const response = await api.post('/people-builder-settings', data)

            if (response.status === 200) {
                const id = response.data.id
                await api.post(`/people-builders/${id}`, data)
            }

            setOpenPeopleBuilderForm(false)
            setOpenBackdrop(false)
            getPeopleBuilderSettings()
            openAlert('People Builder Settings created successful', 'success')
        } catch (error) {
            setOpenBackdrop(false)
            openAlert('Error on create People Builder Settings', 'error')
            console.log(error)
        }
    }

    const getUserPeopleBuilderSettings = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.get('/people-builder-settings')
            const values = response.data.filter(value => value.status !== 'archived' && value.id === peopleBuilderId)

            if (values.length) {
                setStartDate(moment(values[0].start_date))
                setEndDate(moment(values[0].end_date))
                setMidYearDate(months[moment(new Date(values[0].mid_year_checkin).toISOString().split('T')[0]).month()])
                setEndYearDate(months[moment(new Date(values[0].end_year_checkin).toISOString().split('T')[0]).month()])
                setUrl(values[0].file_url)
                setEmailText(values[0].email_message)
            }

            setOpenBackdrop(false)

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
        }
    }

    const updatePeopleBuilderSettings = async (id, data) => {

        setOpenBackdrop(true)

        try {
            const response = await api.post(`/people-builder-settings/${id}`, data)
            console.log(response)
            setOpenBackdrop(false)
            openAlert(response.data.message, 'success')
            setOpenPeopleBuilderForm(false)
            getPeopleBuilderSettings()
        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            openAlert('Error on update People Builder Settings', 'error')
        }
    }

    const getContentEmail = (editorContent) => {
        const plainText = editorContent.replace(/<[^>]+>/g, '');
        const value = plainText.replaceAll('&nbsp;', ' ')
        setEmailText(editorContent)
        setPbData(prevStates => ({
            ...prevStates,
            email_message: editorContent
        }))
        return { html: editorContent, text: value };
    }

    const hasEmptyFields = (data) => {
        let hasEmptyFields = false
        const fieldsFromPbSettingsToCheck = ['start_date', 'end_date', 'mid_year_checkin', 'end_year_checkin', 'file_url']

        Object.keys(data).map(item => {

            if (fieldsFromPbSettingsToCheck.includes(item)) {

                if (item === 'start_date' && !data[item]) {
                    setStartDateEmpty({ error: true, errorText: 'Start Date cannot be empty' });
                    hasEmptyFields = true
                }

                if (item === 'end_date' && !data[item]) {
                    setEndDateEmpty({ error: true, errorText: 'End Date cannot be empty' });
                    hasEmptyFields = true
                }

                if (item === 'mid_year_checkin' && !data[item]) {
                    setMidYearEmpty({ error: true, errorText: 'Mid Year Check In cannot be empty' });
                    hasEmptyFields = true
                }

                if (item === 'end_year_checkin' && !data[item]) {
                    setEndYearEmpty({ error: true, errorText: 'Mid Year Check In cannot be empty' });
                    hasEmptyFields = true
                }

                if (item === 'file_url' && !data[item]) {
                    setPdfEmpty({ error: true, errorText: 'Goals Settings File cannot be empty' });
                    hasEmptyFields = true
                }

            }

        })

        return hasEmptyFields
    }

    useEffect(() => {
        getUserPeopleBuilderSettings()
    }, [])

    return (
        <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
            <BackdropLoader openBackdrop={openBackdrop} />
            <CardContent>
                <FormControl fullWidth sx={{ m: 0 }} >
                    <Stack direction="row" spacing={1}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                        >
                            <DatePicker
                                required={true}
                                label="Start Date"
                                value={startDate}
                                views={['year', 'month', 'day']}
                                sx={{ width: '50%' }}
                                onChange={(newValue) => {
                                    setStartDate(newValue)
                                    setPbData(prevStates => ({ ...prevStates, start_date: newValue }))
                                    setStartDateEmpty({ error: false, errorText: '' })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                format='YYYY-MM-DD'
                                slotProps={{
                                    textField: {
                                        required: true,
                                        helperText: startDateEmpty.errorText,
                                        error: startDateEmpty.error
                                    }
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                        >
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                sx={{ width: '50%' }}
                                views={['year', 'month', 'day']}
                                onChange={(newValue) => {
                                    setEndDate(newValue)
                                    setPbData(prevStates => ({ ...prevStates, end_date: newValue }))
                                    setEndDateEmpty({ error: false, errorText: '' })

                                }}
                                renderInput={(params) => <TextField {...params} />}
                                format='YYYY-MM-DD'
                                slotProps={{
                                    textField: {
                                        required: true,
                                        helperText: endDateEmpty.errorText,
                                        error: endDateEmpty.error
                                    }
                                }}
                            />

                        </LocalizationProvider>
                    </Stack>
                    <Stack direction='row' spacing={2} mt={4}>

                        <FormControl
                            sx={{ width: '50%' }}
                            id="outlined-basic"
                            variant="outlined"
                            required
                            error={midYearEmpty.error}

                        >
                            <InputLabel>Mid-Year Check-In</InputLabel>
                            <Select
                                value={midYearDate}
                                label='Mid-Year Check-In'
                                onChange={(e) => {
                                    const monthSelected = months.indexOf(e.target.value)
                                    const monthsMid = Array.from(months)
                                    setMidYearEmpty({ error: false, errorText: '' })
                                    setMidYearDate(e.target.value)
                                    setMonthsEndYearCheckin(monthsMid.splice(monthSelected, months.length))
                                    startDate ? (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            mid_year_checkin: new Date(
                                                startDate.year(),
                                                monthSelected,
                                                1
                                            )
                                        }))

                                    ) : (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            mid_year_checkin: new Date(
                                                new Date().getFullYear(),
                                                monthSelected,
                                                1
                                            )
                                        }))

                                    )
                                }}
                            >
                                {months.map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{midYearEmpty.errorText}</FormHelperText>
                        </FormControl>
                        <FormControl
                            sx={{ width: '50%' }}
                            id="outlined-basic"
                            variant="outlined"
                            required
                            error={endYearEmpty.error}
                        >
                            <InputLabel>End-Year Check-In</InputLabel>
                            <Select
                                value={endYearDate}
                                label='End-Year Check-In'
                                onChange={(e) => {
                                    const monthSelected = months.indexOf(e.target.value)
                                    setEndYearDate(e.target.value)
                                    setEndYearEmpty({ error: false, errorText: '' })
                                    startDate ? (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            end_year_checkin: new Date(
                                                startDate.year(),
                                                monthSelected,
                                                1
                                            ).toLocaleDateString('en-ca')
                                        }))

                                    ) : (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            end_year_checkin: new Date(
                                                new Date().getFullYear(),
                                                monthSelected,
                                                1
                                            ).toLocaleDateString('en-ca')
                                        }))

                                    )
                                }}
                            >
                                {monthsEndYearCheckin.length ?
                                    monthsEndYearCheckin.map((month, index) => (
                                        <MenuItem key={index} value={month}>{month}</MenuItem>

                                    )) : months.map((month, index) => (
                                        <MenuItem key={index} value={month}>{month}</MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText>{endYearEmpty.errorText}</FormHelperText>
                        </FormControl>
                    </Stack>
                    <Stack spacing={4} mt={4}>
                        <TextField
                            id="end_date outlined-basic"
                            label="Goals Settings File"
                            value={url}
                            required
                            helperText={pdfEmpty.errorText}
                            error={pdfEmpty.error}
                            variant='standard'
                            onChange={(e) => {
                                setUrl(e.target.value)
                                setPdfEmpty({ error: false, errorText: '' })
                                setPbData(prevStates => ({ ...prevStates, file_url: e.target.value }))
                            }}
                            fullWidth
                        />
                        <EmailEditor emailText={emailText} email={getContentEmail} />
                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <Button

                                onClick={() => {
                                    // setPeopleBuilderSettingsForm(false)
                                    setOpenPeopleBuilderForm(false)
                                }}
                                variant="contained"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    peopleBuilderId ?
                                        updatePeopleBuilderSettings(peopleBuilderId, pbData) :
                                        createPeopleBuilderSettings()
                                }}
                                variant="contained"
                            >
                                Save</Button>
                        </Stack>
                    </Stack>
                </FormControl>
            </CardContent>
        </Card>
    )
}

export default NewPeopleBuilderSettings