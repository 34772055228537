import { createContext, useState } from "react";

export const EmployeeFiltersContext = createContext();

export const EmployeeFiltersProvider = ({ children }) => {
    const [etroTeam, setEtroTeam] = useState(false);
    const [strengthsChecked, setStrengthsChecked] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false);
    const [searchText, setSearchText] = useState('');

    return (
        <EmployeeFiltersContext.Provider value={{ setEtroTeam, setStrengthsChecked, setMissingInfo, setSearchText, searchText, etroTeam, strengthsChecked, missingInfo }}>
            {children}
        </EmployeeFiltersContext.Provider>
    );
}