import React, { useContext, useState } from 'react';
import { Box, TextField, Snackbar, Alert, Stack } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SnackbarContext } from '../context/SnackbarContext.js'

const GoalSteps = (props) => {
    const [activeStep, setActiveStep] = useState(0)
    const { openAlert } = useContext(SnackbarContext)
    const { steps, setSteps, onSaveSteps, disabled, setEmptyField, goalId, emptyField, goalData } = props
    const [finishSteps, setFinishSteps] = useState(false)
    const [editSteps, setEditSteps] = useState(true)
    const [focusStep, setFocusStep] = useState(false)

    const handleNext = () => {
        setFocusStep(true)
        if (!steps[activeStep].step) {
            openAlert('Empty Step', 'warning')
            return
        }

        activeStep + 1 === steps.length ?

            setSteps(prevSteps => {
                const newSteps = [...prevSteps]
                newSteps[activeStep].label = newSteps[activeStep].step
                newSteps.push({
                    step_order: newSteps.length,
                    label: `Step ${newSteps.length + 1}`,
                    step: ''
                })
                return newSteps;
            }) :
            setSteps(prevSteps => {
                const newSteps = [...prevSteps]
                newSteps[activeStep].label = newSteps[activeStep].step
                return newSteps;
            })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)

    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        setSteps(prevSteps => {
            const newSteps = [...prevSteps]
            newSteps[activeStep - 1].label = newSteps[activeStep - 1].step
            return newSteps;
        })

    }

    const handleDelete = (index) => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        setSteps(prevSteps => {
            const newSteps = [...prevSteps];
            newSteps.splice(index, 1);
            return newSteps.map((step, newIndex) => ({
                ...step,
                label: step.step,
                step_order: newIndex
            }));
        });
    }

    const updateStepText = (index, text) => {
        const newSteps = steps.map((step, i) =>
            i === index ? { ...step, step: text, step_order: i, goal_id: goalId } : step
        )

        setSteps(newSteps)
        onSaveSteps('steps', newSteps)

    }

    const StepsFromGoal = (last = undefined) => {
        return (
            <Stack>
                <Stepper activeStep={steps.length} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.step_order}>
                            <StepLabel>{step.step}</StepLabel>
                        </Step>
                    ))}
                    {finishSteps || !editSteps && (
                        <Box elevation={2} sx={{ p: 3 }} last={undefined}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button
                                disabled={disabled}
                                onClick={() => {
                                    setEditSteps(true)
                                    setActiveStep(0)
                                }}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Edit
                            </Button>
                        </Box>
                    )}
                </Stepper>

            </Stack>

        )
    }

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Stepper activeStep={editSteps ? activeStep : steps.length} orientation="vertical">
                {editSteps ? (
                    steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>
                                {step.step}
                            </StepLabel>
                            <StepContent>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled={disabled}
                                    autoFocus={focusStep}
                                    value={steps[index].step}
                                    error={emptyField.steps.error}
                                    helperText={emptyField.steps.errorText}
                                    label="Step"
                                    required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                    onChange={(e) => {
                                        setEmptyField({ ...emptyField, steps: { error: false, errorText: '' } })
                                        updateStepText(index, e.target.value)

                                    }}
                                    placeholder='Type here'

                                />
                                <Button
                                    variant="outlined"
                                    disabled={disabled}
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Next
                                </Button>
                                <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="outlined"
                                    disabled={disabled}
                                    onClick={() => {
                                        if (!steps[activeStep].step) {
                                            openAlert('Empty Step', 'warning')
                                            return
                                        }
                                        setActiveStep(steps.length)
                                        setFinishSteps(true)
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Finish
                                </Button>
                                <Button
                                    disabled={index === 0}
                                    onClick={() => handleDelete(index)}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Delete
                                </Button>
                            </StepContent>
                        </Step>
                    ))
                ) : (
                    <StepsFromGoal />

                )
                }
            </Stepper>
            {activeStep >= steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                    </Button>
                </Paper>
            )}
        </Box>
    );
}

export default GoalSteps