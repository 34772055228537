import React from 'react';
import RoutesComponent from './components/RoutesComponent';
import { SlackProvider } from './context/SlackUserInfoContext.js';
import { EmployeesProvider } from './context/UsersContext.js';
import { PeopleBuilderProvider } from './context/PeopleBuilderContext.js';
import { GoalsProvider } from './context/GoalsContext.js';
import { CheckInProvider } from './context/CheckInContexts.js';
import SnackbarAlert from './components/SnackbarAlert';
import { OrgUnitPathsProvider } from './context/OrgUnitPathsContext.js';
import LoaderProvider from './context/LoaderContext.js';
import { StrengthsProvider } from './context/StrengthsContext.js';
import { EmployeeFiltersProvider } from './context/EmployeeFiltersContext.js';

const App = () => {

    return (
        <>
            <SnackbarAlert />
            <LoaderProvider>
                <StrengthsProvider>
                    <OrgUnitPathsProvider >
                        <CheckInProvider>
                            <GoalsProvider>
                                <PeopleBuilderProvider>
                                    <SlackProvider>
                                        <EmployeesProvider>
                                            <EmployeeFiltersProvider>
                                                <RoutesComponent />
                                            </EmployeeFiltersProvider>
                                        </EmployeesProvider>
                                    </SlackProvider>
                                </PeopleBuilderProvider>
                            </GoalsProvider>
                        </CheckInProvider>
                    </OrgUnitPathsProvider>
                </StrengthsProvider>
            </LoaderProvider>

        </>
    );
}

export default App;