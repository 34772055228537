import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'
import {
    Stack, Paper, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, IconButton, Tooltip, Box, Chip
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility';
import BackdropLoader from './common/BackdropLoader.jsx'
import AdditionalInformation from './AdditionalInformation.jsx'
import FirstGoalPDF from './FirstGoal.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { GoalsContext } from '../context/GoalsContext.js';
import { CheckInContext } from '../context/CheckInContexts.js';
import { deleteGoalAPI } from '../apis/goalsAPI.js'
import { LoaderContext } from '../context/LoaderContext.js'
import { SnackbarContext } from '../context/SnackbarContext.js'
import SendEmailReadyForApproval from './SendEmailReadyForApproval.jsx'

const Goals = (props) => {
    const { playerId, peopleBuilder, pbClosed } = props
    const { getGoals, goals } = useContext(GoalsContext)
    const { getCheckIns, checkIns } = useContext(CheckInContext)
    const { loading, setLoading } = useContext(LoaderContext)
    const { openAlert } = useContext(SnackbarContext)
    const [userGoals, setUserGoals] = useState([{
        goal_name: '',
        goal_type: '',
        target_date: '',
        status: '',
        last_check_in: '',
        check_in_status: '',
        id: 0
    }])
    const [openFirstGoalPDF, setOpenFirstGoalPDF] = useState(false)
    const navigate = useNavigate();
    const [openDelete, setOpenDelete] = useState(false)
    const [goalSelectedToDelete, setGoalSelectedToDelete] = useState({ goal_name: '', id: '' })
    const [userLogged] = useState(JSON.parse(localStorage.getItem('user')))
    const [isCoach] = useState(userLogged.id === peopleBuilder.coach_id)
    const [isPlayer] = useState(userLogged.id === peopleBuilder.player_id)
    const [isAdmin] = useState(userLogged.is_admin)
    const [loadingGoals, setLoadingGoals] = useState(true)
    const [userGoalsStatusActive, setUserGoalsStatusActive] = useState(false)
    const [approvalCount, setApprovalCount] = useState(false)
    const [pagination, setPagination] = useState({ page: 0, pageSize: 6 })
    const columns = useMemo(() => [
        {
            field: 'goal_name',
            headerName: 'Goal Name',
            headerClassName: 'goal-header',
            width: 300,
            renderCell: (params) => {
                return (
                    <Stack direction='row' gap={2} alignItems='center' spacing={1}>
                        {params.row.goal_name}
                        {params.row.inheritance && (
                            <Tooltip title='Inherited from last year'>
                                <Chip label='Inherited' color='primary' />
                            </Tooltip>
                        )}
                    </Stack>
                )
            }
        },
        {
            field: 'goal_type',
            headerName: 'Goal Type',
            headerClassName: 'goal-header',
            width: 350,
        },
        {
            field: 'target_date',
            headerName: 'Target Date',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'goal-header',
            width: 150,
        },
        {
            field: 'last_check_in',
            headerName: 'Last Check-In',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'check_in_status',
            headerName: 'Check-In Status',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1,
            renderCell: (params) => {
                return (
                    params.row.status === 'Active' || !params.row.pb_closed ?
                        (
                            <IconButton>
                                <VisibilityIcon />
                            </IconButton>
                        ) : (
                            <Stack direction='row' spacing={1}>
                                <IconButton onClick={(event) => handleRowClick(params.id)} >
                                    <Tooltip title='Edit Goal'>
                                        <EditIcon />
                                    </Tooltip >
                                </IconButton >
                                <IconButton>
                                    <Tooltip title='Delete Goal'>
                                        <DeleteIcon
                                            className="delete-icon"
                                            onClick={() => {

                                                if (!isPlayer && !isCoach) {
                                                    openAlert('You are not own from this goal', 'info')
                                                    return
                                                }

                                                setOpenDelete(true)
                                                setGoalSelectedToDelete({ goal_name: params.row.goal_name, id: params.row.id })
                                            }} />
                                    </Tooltip>
                                </IconButton>
                            </Stack >
                        )
                )
            },
        },
    ], [pbClosed, isCoach, isPlayer])

    const handleClose = () => {
        setOpenFirstGoalPDF(false)
    }

    const handleRowClick = useCallback(async (goalId) => {
        const goalSelected = goals.find(goal => goal.id === goalId)

        if (!isCoach && !isPlayer && !isAdmin) {
            openAlert('You are not own from this goal', 'error')
            return
        }

        navigate(`/goal-form/people-builder/${goalSelected?.people_builder_id}/goal/${goalId}`)
    }, [goals])

    const openGoalForm = () => {
        const filterGoalsByUser = goals.filter(goal => goal?.people_builder?.player_id === playerId && !goal.goal_status.includes('archived'))
        if (!filterGoalsByUser.length) {
            setOpenFirstGoalPDF(true)
        } else {
            navigate(`/goal-form/people-builder/${peopleBuilder?.id}`)
        }

    }

    const findMostRecentCheckin = (checkins) => {
        if (!checkins?.length) return null;

        return checkins.reduce((mostRecent, current) => {
            return new Date(current.check_in_date) > new Date(mostRecent.check_in_date) ? current : mostRecent;
        });
    };

    const getUserGoals = useCallback(async () => {
        // await getGoals()
        const userGoalsFiltered = goals.filter(goal => goal?.people_builder?.player_id === playerId && !goal.goal_status.includes('archived') && peopleBuilder.year === goal.people_builder.year)
        const userCheckins = checkIns?.filter(checkin => checkin.player_id === playerId)
        const mostRecentCheckin = findMostRecentCheckin(userCheckins);
        const goalsFixed = userGoalsFiltered.map((goal, index) => {

            return {
                goal_name: goal.goal_name,
                goal_type: goal.goal_term,
                target_date: goal.target_date ? new Date(goal.target_date)?.toISOString().split('T')[0] : '',
                status: goal.goal_status,
                last_check_in: mostRecentCheckin?.check_in_goals[index]?.goal.last_check_in.split('T')[0],
                check_in_status: mostRecentCheckin?.check_in_goals[index]?.check_in_goal_status ?? '',
                id: goal.id,
                inheritance: goal.inheritance,
                pb_closed: pbClosed
            }
        })
        const statusUserGoals = userGoalsFiltered.map(goal => goal.goal_status)
        const statusActive = statusUserGoals.length ? statusUserGoals.every(status => status === 'Active') : false

        setApprovalCount(statusUserGoals.filter(status => status === 'Approval').length >= 3)
        setUserGoalsStatusActive(statusActive)
        setUserGoals(goalsFixed)
        setLoadingGoals(false)
    }, [goals, checkIns])

    const deleteGoal = async () => {
        setLoading(true)
        setOpenDelete(false)
        const deletedGoal = await deleteGoalAPI(goalSelectedToDelete.id)
        setLoading(false)
        openAlert(deletedGoal?.message)
        await getGoals()
        await getCheckIns()
    }

    const getRowClassName = (params) => {

        if (params.row.status === 'Draft Goal' && isCoach) {
            return 'disabled-row';
        } else if (!isCoach && !isPlayer && !isAdmin) {
            return 'disabled-row';
        }
        return '';
    };

    const getDataGridStyles = () => ({
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            backgroundColor: 'none'
        },
        '& .MuiDataGrid-cell:hover': {
            backgroundColor: 'none',
        },
        '& .goal-header': {
            backgroundColor: '#ccc',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    });

    useEffect(() => {
        getUserGoals()
    }, [goals, checkIns])

    useEffect(() => {
        getGoals()
        getCheckIns()
    }, [])

    return (
        <React.Fragment>
            <Paper>
                <BackdropLoader openBackdrop={loading} />
                {userGoalsStatusActive && (
                    <AdditionalInformation peopleBuilder={peopleBuilder} />
                )}
                <DataGrid
                    rows={userGoals}
                    columns={columns}
                    disableColumnResize={true}
                    rowSelection={false}
                    getRowClassName={getRowClassName}
                    loading={loadingGoals}
                    localeText={{ noRowsLabel: "No goals" }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton'
                        }
                    }}
                    onRowClick={(params, event) => {

                        if (!event.target.closest('.delete-icon')) {
                            handleRowClick(params.id)
                        }
                    }}
                    paginationModel={pagination}
                    onPaginationModelChange={(params) => setPagination((prev) => ({ ...prev, pageSize: 6, page: params.page }))}
                    sx={getDataGridStyles}
                />

            </Paper>
            {
                pbClosed ? (
                    isCoach || isPlayer ? (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', mt: 2, position: 'relative' }}>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title='Add New Goal'>
                                    <IconButton onClick={openGoalForm}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            {(approvalCount && !isCoach) && (
                                <Box sx={{ position: 'absolute', right: 0 }}>
                                    <SendEmailReadyForApproval peopleBuilder={peopleBuilder} />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Stack>
                            <Tooltip title={`You do not have access to create new Goal`}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <IconButton disabled>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Stack>
                    )

                ) : (
                    <Stack>
                        <Tooltip title={`People Builder is closed`}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <IconButton disabled>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Stack>
                )

            }
            <Dialog
                open={openFirstGoalPDF}
                onClose={handleClose}
                onBlur={handleClose}
                fullWidth={true}
                maxWidth='xl'
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <FirstGoalPDF peopleBuilder={peopleBuilder} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDelete}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {goalSelectedToDelete.goal_name}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
                    <Button onClick={deleteGoal}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default Goals