import React from 'react'
import WorkLocations from '../components/WorkLocations'

const WorkLocationsPage = () => {
    return (
        <React.Fragment>
            <WorkLocations />
        </React.Fragment>
    )
}

export default WorkLocationsPage