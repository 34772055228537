import React, { useState, useCallback, useEffect, useContext } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Stack, IconButton,
    Fade, TextField, FormControl, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close';
import GlobalStyles from '../css/GlobalStyles.module.css'
import {
    getOrgUnitPathsAPI,
    createOrgUnitPathAPI,
    updateOrgUnitPathAPI,
    deleteOrgUnitPathAPI,
    createOrgOnGoogleAPI,
    updateOrgOnGoogleAPI,
    deleteOrgUnitPathOnGoogleAPI
} from '../apis/orgUnitPathAPI.js';
import { OrgUnitPathsContext } from '../context/OrgUnitPathsContext.js';
import { SnackbarContext } from '../context/SnackbarContext.js';
import BackdropLoader from './common/BackdropLoader.jsx';

const OrgUnitTable = (props) => {
    const { allOrgUnitPath, fetchOrgUnitPaths, orgUnitPath, setOrgUnitPath } = useContext(OrgUnitPathsContext)
    const { openAlert } = useContext(SnackbarContext)
    const { search, setNewOrgPathForms, setOrgData, orgData } = props
    const [editingRowId, setEditingRowId] = useState('')
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [openDeleteOrgUnitPath, setOpenDeleteOrgUnitPath] = useState(false)
    const [orgUnitPathSelectedToDelete, setOrgUnitPathSelectedToDelete] = useState({ orgUnitPath: '', id: '' })

    const searchingOrgUnitPath = (search) => {
        const orgUnitPathFiltered = allOrgUnitPath.filter(org => {
            return org.org_unit_path.toLowerCase().includes(search.toLowerCase()) || org.description.toLowerCase().includes(search.toLowerCase())
        })
        setOrgUnitPath(orgUnitPathFiltered)
    }

    const updateOrg = async (id, data) => {
        setOpenBackdrop(true);

        try {
            const res = await updateOrgUnitPathAPI(id, data);

            if (res.status === 200) {
                openAlert('Organization Unit Paths updated successful', 'success');
                setOpenBackdrop(false);
                fetchOrgUnitPaths();
            }
            setOpenBackdrop(false);
            await updateOrgOnGoogle(id, data);
        } catch (error) {
            openAlert(error.message, 'error');
            setOpenBackdrop(false);
            console.log(error);
        }

        setEditingRowId(null);
    };

    const updateOrgOnGoogle = useCallback(async (id, data) => {
        try {
            await updateOrgOnGoogleAPI(id, data);
        } catch (error) {
            console.log(error);
        }

        setEditingRowId(null);
    }, []);

    const deleteOrgUnitPath = async () => {
        setOpenBackdrop(true);
        setOpenDeleteOrgUnitPath(false);
        const id = orgUnitPathSelectedToDelete.id;
        try {
            await deleteOrgUnitPathAPI(id);
            openAlert('Organization Unit Paths deleted successful', 'success');
            fetchOrgUnitPaths();
            await deleteOrgUnitPathOnGoogle(id);
            setOpenBackdrop(false);
        } catch (error) {
            openAlert(error.message, 'error');
            setOpenBackdrop(false);
            console.log('error', error);
        }
    };

    const deleteOrgUnitPathOnGoogle = useCallback(async (id) => {
        const orgFiltered = orgUnitPath.filter(org => org.id === id);

        if (orgFiltered.length) {
            try {
                await deleteOrgUnitPathOnGoogleAPI(orgFiltered[0].org_unit_path);
            } catch (error) {
                console.log('error Google', error);
            }
        }
    }, [orgUnitPath]);

    useEffect(() => {
        searchingOrgUnitPath(search)
    }, [search])

    useEffect(() => {
        fetchOrgUnitPaths();
    }, []);

    return (
        <>
            <BackdropLoader openBackdrop={openBackdrop} />
            <TableContainer sx={{ boxShadow: 3 }} className={GlobalStyles.tableContainer}>
                <Table stickyHeader className={GlobalStyles.tableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#ccc' }}>Organization Unit Path</TableCell>
                            <TableCell sx={{ backgroundColor: '#ccc' }}>Description</TableCell>
                            <TableCell sx={{ backgroundColor: '#ccc' }}>ETRO Team</TableCell>
                            <TableCell sx={{ backgroundColor: '#ccc' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orgUnitPath.map(org => (
                            <TableRow
                                hover
                                key={org.id}
                                className={GlobalStyles.cursor_pointer}
                                sx={{ height: '10vh' }}
                            >
                                <TableCell
                                    sx={{ width: '30%' }}
                                    size='small'
                                    id="org-unit-path">
                                    {editingRowId === org.id ? (
                                        <Fade in={editingRowId === org.id} timeout={500}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={orgData.org_unit_path}
                                                onChange={(e) => {
                                                    const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                        item.id === org.id ? { ...item, org_unit_path: e.target.value } : item
                                                    )
                                                    setOrgUnitPath(updatedOrgUnitPath)
                                                    setOrgData(prevState => ({
                                                        ...prevState,
                                                        org_unit_path: e.target.value
                                                    }))
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        updateOrg(org.id, orgData);
                                                    }
                                                }}
                                                autoFocus
                                            />
                                        </Fade>
                                    ) : (
                                        org.org_unit_path
                                    )}
                                </TableCell>
                                <TableCell
                                    sx={{ width: '40%' }}
                                    size='small'
                                    id="description">
                                    {editingRowId === org.id ? (
                                        <Fade in={editingRowId === org.id} timeout={500}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={orgData.description}
                                                onChange={(e) => {
                                                    setEditingRowId(org.id)
                                                    const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                        item.id === org.id ? { ...item, description: e.target.value } : item
                                                    )

                                                    setOrgUnitPath(updatedOrgUnitPath)
                                                    setOrgData(prevState => ({
                                                        ...prevState,
                                                        description: e.target.value
                                                    }))
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        updateOrg(org.id, orgData);
                                                    }
                                                }}
                                            />
                                        </Fade>
                                    ) : (
                                        org.description
                                    )}
                                </TableCell>
                                <TableCell
                                    sx={{ width: '10%' }}
                                    size='small'
                                    id="etro-team">
                                    {editingRowId === org.id ? (
                                        <Fade in={editingRowId === org.id} timeout={500}>
                                            <FormControl sx={{ minWidth: 100 }}>
                                                <Select
                                                    value={orgData.etro_team}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setEditingRowId(org.id)
                                                        const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                            item.id === org.id ? { ...item, etro_team: e.target.value } : item
                                                        )

                                                        setOrgUnitPath(updatedOrgUnitPath)
                                                        setOrgData(prevState => ({
                                                            ...prevState,
                                                            etro_team: e.target.value
                                                        }))
                                                    }}
                                                >
                                                    <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                                    <MenuItem key='0' value='No'>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Fade>
                                    ) : (
                                        org.etro_team ?? ''
                                    )}

                                </TableCell>
                                <TableCell
                                    size='small'
                                    sx={{ width: '12%' }}
                                >
                                    {(editingRowId !== org.id) && (
                                        // <Fade in={focusedRow === org.id} timeout={500}>
                                        <Stack direction='row' spacing={1}>
                                            <IconButton
                                                onClick={(event) => {
                                                    setEditingRowId(org.id)
                                                    setNewOrgPathForms(false)
                                                    setOrgData({
                                                        org_unit_path: org.org_unit_path,
                                                        parent_org_unit_path: org.org_unit_path,
                                                        description: org.description,
                                                        etro_team: org.etro_team
                                                    })

                                                }}
                                                size="small" >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    // deleteOrgUnitPath(org.id)
                                                    setOpenDeleteOrgUnitPath(true)
                                                    setOrgUnitPathSelectedToDelete({ orgUnitPath: org.org_unit_path, id: org.id })

                                                }}
                                                size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                        // </Fade>
                                    )}
                                    {editingRowId === org.id && (
                                        <Stack direction='row'>
                                            <IconButton
                                                onClick={() => {
                                                    updateOrg(org.id, orgData)
                                                }}
                                                size="small">
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton onClick={() => setEditingRowId(null)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openDeleteOrgUnitPath}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {orgUnitPathSelectedToDelete.orgUnitPath}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteOrgUnitPath(false)}>Cancel</Button>
                    <Button onClick={deleteOrgUnitPath} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrgUnitTable;