import { createContext, useState } from "react";
import api from "../apis/api";

export const StrengthsContext = createContext();

export const StrengthsProvider = ({ children }) => {
    const [strengths, setStrengths] = useState([])

    const getStrengths = async () => {
        try {
            const response = await api.get(`/user/strengths`)
            setStrengths(response.data)
            return response.data
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <StrengthsContext.Provider value={{ strengths, getStrengths }}>
            {children}
        </StrengthsContext.Provider>
    );
}