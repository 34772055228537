// src/components/columns.js
import { Avatar, Chip } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';


const employeesColumns = [
    {
        field: 'full_name',
        headerName: 'Name',
        width: 400,
        headerClassName: 'users-header',
        renderCell: (params) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.5' }}>
                    {params.row.suspended ? (
                        <PersonOffIcon sx={{ fontSize: '40px', color: 'gray', mr: 2 }} />
                    ) : (
                        <Avatar src={params?.row.photo_url} sx={{ width: 40, height: 40, marginRight: 2 }} />
                    )}
                    {params.value}
                    {params.row.suspended ? (
                        <Chip label='Suspended' variant='outlined' color='error' style={{ marginLeft: '10px' }} />
                    ) : (
                        (params.row.is_admin || params.row.is_delegated_admin) && (
                            <img style={{ alignContent: 'center', width: '20px', marginLeft: '10px' }} src='https://iili.io/Jazab4a.png' />
                        )
                    )}
                </div>
            );
        }
    },
    {
        field: 'title',
        headerName: 'Title',
        width: 250,
        headerClassName: 'users-header',
    },
    {
        field: 'department',
        headerName: 'Department',
        width: 200,
        headerClassName: 'users-header',
    },
    {
        field: 'coach',
        headerName: 'Coach',
        width: 200,
        headerClassName: 'users-header',
    },
    {
        field: 'work_location',
        headerName: 'Work Location',
        width: 200,
        headerClassName: 'users-header',
    },
    {
        field: 'phone',
        headerName: 'Contact Phone',
        width: 204,
        sortable: false,
        headerClassName: 'users-header',
    }
];

export default employeesColumns;