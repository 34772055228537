import api from './api';

export const getOrgUnitPathsAPI = async () => {
    const response = await api.get('/org-unit-path');
    return response.data;
};

export const createOrgUnitPathAPI = async (data) => {
    const response = await api.post('/org-unit-path', data);
    return response.data;
};

export const updateOrgUnitPathAPI = async (id, data) => {
    const response = await api.put(`/org-unit-path/${id}`, data);
    return response.data;
};

export const deleteOrgUnitPathAPI = async (id) => {
    const response = await api.delete(`/org-unit-path/${id}`);
    return response.data;
};

export const createOrgOnGoogleAPI = async (data) => {
    const response = await api.post('/google-create/org-unit-path/', data);
    return response.data;
};

export const updateOrgOnGoogleAPI = async (id, data) => {
    const response = await api.post(`/google-update/org-unit-path/${id}`, data);
    return response.data;
};

export const deleteOrgUnitPathOnGoogleAPI = async (id) => {
    const response = await api.delete(`/google/org-unit-path/${id}`);
    return response.data;
};