import React from 'react'
import EmployeeProfile from '../components/EmployeeProfile.jsx'

const EmployeeProfilePage = () => {
    return (
        <React.Fragment>
            <EmployeeProfile />
        </React.Fragment>
    )
}

export default EmployeeProfilePage