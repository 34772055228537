import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function InputSearch(props) {
    const {sizeProp, list, onChange, label } = props

    return (
        <Autocomplete
            size='small'
            sx={{ width: sizeProp }}
            options={list}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={onChange}
        />
    );
}