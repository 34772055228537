import React, { useContext, useEffect, useState, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { FormGroup, FormControlLabel, Checkbox, Stack, Card, CardContent, Container, Chip } from '@mui/material';
import { EmployeesContext } from '../context/UsersContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchField from "./common/SearchField.jsx";
import employeesColumns from './utilities/employeesColumns.js';
import { StrengthsContext } from '../context/StrengthsContext.js';
import { EmployeeFiltersContext } from '../context/EmployeeFiltersContext.js';
import api from '../apis/api.js';

const ListUsersWithDataGrid = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const { strengths, getStrengths } = useContext(StrengthsContext)
    const { etroTeam, strengthsChecked, missingInfo, setMissingInfo, setStrengthsChecked, setEtroTeam, setSearchText, searchText } = useContext(EmployeeFiltersContext)
    const [rows, setRows] = useState([])
    // const [search, setSearch] = useState('')
    const [filterUsed, setFilterUsed] = useState(false)
    const [lastRunGetUsers, setLastRunGetUsers] = useState('')
    // const [checked, setChecked] = useState(false)
    // const [checkedStrengths, setCheckedStrengths] = useState(false)
    // const [checkedInfo, setCheckedInfo] = useState(false)
    const location = useLocation()
    const currentPath = location.pathname
    const navigate = useNavigate()
    const [pageSizes, setPageSizes] = useState(10);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: pageSizes,
        page: 0,
    });

    const getChip = () => {
        if (etroTeam || strengthsChecked || missingInfo || searchText.length || filterUsed) {
            return <Chip style={{ marginLeft: '12%' }} label="Filtered" variant="filled" color='info' />
        }
    }

    const usersDataFixed = () => {
        let usersEtroTeam

        if (searchText) {
            usersEtroTeam = employees.filter(employee => (
                (
                    employee?.work_location?.building_name?.toLowerCase().includes(searchText.toLowerCase()) ||
                    employee.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    employee?.coach.toLowerCase().includes(searchText.toLowerCase()) ||
                    employee?.title?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
                    employee?.department?.department?.toLowerCase().includes(searchText.toLowerCase())
                ) &&
                employee?.etro_team !== etroTeam
            ))

            usersEtroTeam = usersEtroTeam.filter(employee => {
                let userStrengths = strengths.find(strength => strength.user_id === employee.id)
                userStrengths = (userStrengths !== undefined) && strengthsChecked
                let checkingMissingInfo = (!employee.coach) === missingInfo ||
                    (!employee?.department) === missingInfo ||
                    (!employee?.title) === missingInfo ||
                    (!employee?.work_location) === missingInfo ||
                    (!employee?.phone) === missingInfo

                if (strengthsChecked) {
                    return !userStrengths && employee.etro_team
                } else if (missingInfo) {
                    return employee.etro_team && (checkingMissingInfo || !employee.photo_url)
                } else if (etroTeam) {
                    return etroTeam ? employee.etro_team !== etroTeam : checkingMissingInfo && employee.etro_team !== etroTeam
                } else {
                    return employee.etro_team !== etroTeam
                }
            })

        } else {
            usersEtroTeam = employees.filter(employee => {
                let userStrengths = strengths.find(strength => strength.user_id === employee.id)
                userStrengths = (userStrengths !== undefined) && strengthsChecked
                let checkingMissingInfo = (!employee.coach) === missingInfo ||
                    (!employee?.department) === missingInfo ||
                    (!employee?.title) === missingInfo ||
                    (!employee?.work_location) === missingInfo ||
                    (!employee?.phone) === missingInfo

                if (strengthsChecked) {
                    return !userStrengths && employee.etro_team
                } else if (missingInfo) {
                    return employee.etro_team && (checkingMissingInfo || !employee.photo_url)
                } else if (etroTeam) {
                    return etroTeam ? employee.etro_team !== etroTeam : checkingMissingInfo && employee.etro_team !== etroTeam
                } else {
                    return employee.etro_team !== etroTeam
                }
            })
        }

        const users = usersEtroTeam.map(employee => ({
            id: employee.id,
            is_admin: employee?.is_admin,
            is_delegated_admin: employee?.is_delegated_admin,
            full_name: employee?.full_name ?? '',
            title: employee?.title?.title ?? '',
            department: employee?.department?.department ?? '',
            coach: employee?.coach ?? '',
            work_location: employee?.work_location?.building_name ?? '',
            phone: employee?.phone ?? '',
            photo_url: employee?.photo_url ?? '',
            suspended: employee?.suspended ?? ''
        }))
        setRows(users)
    }

    const getCronLogs = async () => {
        try {
            const cronlogs = await api.get('/cronlogs')
            const cronlogFiltered = cronlogs?.data.filter(cron => cron.name === 'bringing users from google')
            const theMostRecentLog = cronlogFiltered?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
            const lastRun = theMostRecentLog?.created_at ? new Date(theMostRecentLog.created_at).toLocaleDateString('en-ca', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) : ''
            setLastRunGetUsers(lastRun)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCronLogs()
    }, [])

    useEffect(() => {
        usersDataFixed()
    }, [searchText, etroTeam, employees, missingInfo, strengthsChecked])

    const handleUserToProfile = useCallback((user) => {

        if (currentPath === '/') {
            navigate(`employee/profile/${user.id}`)
        } else {
            navigate(`profile/${user.id}`)
        }
    })

    useEffect(() => {
        fetchUsers()
        setPaginationModel((prev) => ({ ...prev, pageSize: parseInt(localStorage.getItem('pageSize')), page: 0 }))
        getStrengths()
        // const handleResize = () => {
        //     const height = window.innerHeight;
        //     const rowHeight = 52;
        //     const headerHeight = 56;
        //     const footerHeight = 56;
        //     const availableHeight = height - headerHeight - footerHeight - 200;
        //     let calculatedPageSize = Math.floor(availableHeight / rowHeight);
        //     calculatedPageSize = Math.floor(calculatedPageSize / 5) * 5; // Round down to nearest multiple of 5
        //     setPaginationModel((prev) => ({ ...prev, pageSize: calculatedPageSize }));
        // };

        // window.addEventListener('resize', handleResize);
        // handleResize();

        // return () => window.removeEventListener('resize', handleResize);
    }, []);

    const setNewPageSize = useCallback((pageSize) => {
        if (pageSize) {
            localStorage.setItem('pageSize', pageSize);
            return
        }
        localStorage.setItem('pageSize', parseInt(localStorage.getItem('pageSize')) || 10);
    }, [paginationModel])

    useEffect(() => {
        setNewPageSize()
    }, [pageSizes])

    return (
        <Container maxWidth="xl" sx={{ mt: 10 }}>
            <Card>
                <CardContent>
                    <Stack direction="row" alignItems='center' spacing={2}>
                        <SearchField search={searchText} setSearch={setSearchText} />
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={etroTeam} />}
                                label="Not ETRO Team"
                                onChange={(event) => {
                                    setEtroTeam(event.target.checked)
                                    setStrengthsChecked(false)
                                    setMissingInfo(false)
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={strengthsChecked} />}
                                label="No Clifton Strengths"
                                onChange={(event) => {
                                    setStrengthsChecked(event.target.checked)
                                    setEtroTeam(false)
                                    setMissingInfo(false)
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={missingInfo} />}
                                label="Missing Info"
                                onChange={(event) => {
                                    setMissingInfo(event.target.checked)
                                    setEtroTeam(false)
                                    setStrengthsChecked(false)
                                }} />
                        </FormGroup>
                        <Chip label={`Last updated at: ${lastRunGetUsers}`} variant="outlined" color='info' />
                        {getChip()}
                    </Stack>
                    <DataGrid
                        autoHeight={true}
                        disableColumnResize
                        rows={rows}
                        columns={employeesColumns}
                        onRowClick={(e) => handleUserToProfile(e)}
                        disableRowSelectionOnClick
                        paginationModel={paginationModel}
                        onPaginationModelChange={(model) => {
                            setPaginationModel((prev) => ({ ...prev, pageSize: model.pageSize, page: model.page }))
                            setNewPageSize(model.pageSize)
                        }}
                        onFilterModelChange={(model) => {
                            model?.items[0]?.value ? setFilterUsed(true) : setFilterUsed(false)
                        }}
                        pageSizeOptions={[10, 15, 20]}
                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:hover': {
                                backgroundColor: 'none',
                            },
                            '& .users-header': {
                                backgroundColor: '#ccc',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    )
}

export default ListUsersWithDataGrid