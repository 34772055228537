import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../apis/api';

const SlackCallback = () => {
    const navigate = useNavigate();

    const getSlackToken = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const userStored = localStorage.getItem('user')
        const user = JSON.parse(userStored)
        const data = { code, user }
        
        if (code) {
            try {
                const response = await api.post('/slack/api/auth', data)
                localStorage.setItem('slackUserData', JSON.stringify(response.data))
                navigate('/employee');
    
            } catch (error) {
                console.error('Erro ao autenticar com o Slack:', error);
            }
    
        }
    }

    useEffect(() => {
        getSlackToken()
    }, []);

    return <div>Autenticando...</div>;
};

export default SlackCallback;