import { useState, createContext } from "react";

export const LoaderContext = createContext()

const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    return (
        <LoaderContext.Provider value={{ loading, setLoading }} >
            {children}
        </LoaderContext.Provider>
    )
}

export default LoaderProvider

