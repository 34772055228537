import { createContext, useState, useEffect } from "react";
import api from '../apis/api.js'
export const EmployeesContext = createContext();

export const EmployeesProvider = ({ children }) => {
    const [employees, setEmployees] = useState([]);
    const fetchUsers = async () => {

        const token = localStorage.getItem('token');

        try {
            const response = await api.get(`/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            const data = response.data
            if (data.length) {
                data.sort((a, b) => a.full_name.localeCompare(b.full_name))
                setEmployees(data);
            } else {
                setEmployees([]);
            }


        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <EmployeesContext.Provider value={{ employees, fetchUsers }}>
            {children}
        </EmployeesContext.Provider>
    )
}
