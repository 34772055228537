import { React, useCallback, useEffect, useState, useContext } from 'react';
import { Chip, Stack, Tooltip, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { StrengthsContext } from '../context/StrengthsContext.js';

function Strengths(props) {
    const [employeeStrength, setEmployeeStrength] = useState([])
    const { strengths, getStrengths } = useContext(StrengthsContext)
    const [skeleton, setSkeleton] = useState(true)

    useEffect(() => {
        const getUserStrengths = async () => {
            const userStrengths = strengths.find(strength => strength.user_id === props.employeeId)

            if (userStrengths === undefined) {
                setEmployeeStrength(['No strengths found'])
                setSkeleton(false)
            } else {
                setEmployeeStrength([userStrengths])
                setSkeleton(false)
            }
        }
        getUserStrengths()

    }, [strengths, props.employeeId])

    useEffect(() => {
        getStrengths()
    }, [])

    return (
        <div>
            {skeleton ? (
                <Stack spacing={1}>
                    <Skeleton animation='wave' variant="text" width={552} height={24} />
                    <Skeleton animation='wave' variant="rectangular" width={552} height={64} />
                </Stack>
            ) : (
                employeeStrength[0] !== 'No strengths found' ? (
                    employeeStrength.map((strength, index) => (
                        <Card key={1} sx={{ alignItems: 'center', minWidth: 552 }}>
                            <Typography sx={{ 'textAlign': 'center' }} >CliftonStrengths®</Typography>
                            <CardContent>
                                <Stack justifyContent='space-between' direction="row" spacing={2}>
                                    <Tooltip title={strength.description_1} placement="top">
                                        <Chip sx={{ backgroundColor: strength.color_1, color: 'white' }} label={strength.strength_1} />
                                    </Tooltip>
                                    <Tooltip title={strength.description_2} placement="top">
                                        <Chip sx={{ backgroundColor: strength.color_2, color: 'white' }} label={strength.strength_2} />
                                    </Tooltip>
                                    <Tooltip title={strength.description_3} placement="top">
                                        <Chip sx={{ backgroundColor: strength.color_3, color: 'white' }} label={strength.strength_3} />
                                    </Tooltip>
                                    <Tooltip title={strength.description_4} placement="top">
                                        <Chip sx={{ backgroundColor: strength.color_4, color: 'white' }} label={strength.strength_4} />
                                    </Tooltip>
                                    <Tooltip title={strength.description_5} placement="top">
                                        <Chip sx={{ backgroundColor: strength.color_5, color: 'white' }} label={strength.strength_5} />
                                    </Tooltip>
                                </Stack>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <Card key={1} sx={{ alignItems: 'center', minWidth: 552 }}>
                        <Typography sx={{ 'textAlign': 'center' }} >CliftonStrengths®</Typography>
                        <CardContent>
                            <Typography sx={{ 'textAlign': 'center' }} >No strengths found</Typography>
                        </CardContent>
                    </Card>
                )
            )
            }
        </div >
    );
}

export default Strengths