import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Paper, Typography } from '@mui/material';

const EmailEditor = (props) => {
    const { email, slack, emailText, slackText } = props
    const editorRefEmail = useRef(null)
    const editorRefSlack = useRef(null)
    // const log = () => {
    //     if (editorRef.current) {
    //         console.log(editorRef.current.getContent());
    //     }
    // };


    // const getContentEmail = () => {
    //     const editorContent = editorRefEmail.current.getContent();
    //     const plainText = editorContent.replace(/<[^>]+>/g, '');
    //     plainText.replaceAll('&nbsp;', ' ')
    //     return { html: editorContent, text: plainText };
    // };

    // const getContentSlack = () => {
    //     const editorContent = editorRefSlack.current.getContent();
    //     const plainText = editorContent.replace(/<[^>]+>/g, '');
    //     plainText.replaceAll('&nbsp;', ' ')
    //     return { html: editorContent, text: plainText };
    // };

    return (
        <>
            <Typography variant="h6" align='center' color="initial">Email Message</Typography>
            <Editor
                apiKey='8ge9u12yekwfb5uky5zyvu3owsslrx6pmxzg0tystas92g9e'
                onInit={(_evt, editor) => editorRefEmail.current = editor}
                value={emailText}
                onEditorChange={() => {
                    email(editorRefEmail.current.getContent())
                }}
                init={{
                    height: 200,
                    menubar: false,
                    placeholder: 'Annoucement Message to be sent by email',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

            <Typography variant="h6" align='center' color="initial">Slack Message</Typography>
            <Editor
                apiKey='8ge9u12yekwfb5uky5zyvu3owsslrx6pmxzg0tystas92g9e'
                onInit={(_evt, editor) => editorRefSlack.current = editor}
                value={slackText}
                onEditorChange={() => {
                    slack(editorRefSlack.current.getContent())
                }}
                init={{
                    height: 200,
                    menubar: false,
                    placeholder: 'Annoucement Message to be sent by Slack',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

            {/* <button onClick={log}>Log editor content</button> */}
        </>
    );
}

export default EmailEditor