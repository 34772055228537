import React, { createContext, useState, useCallback } from 'react'
import { getOrgUnitPathsAPI } from '../apis/orgUnitPathAPI.js';
export const OrgUnitPathsContext = createContext();

export const OrgUnitPathsProvider = ({ children }) => {
    const [orgUnitPath, setOrgUnitPath] = useState([]);
    const [allOrgUnitPath, setAllOrgUnitPath] = useState([]);

    const fetchOrgUnitPaths = useCallback(async () => {
        try {
            const data = await getOrgUnitPathsAPI();
            data.sort((a, b) => a.org_unit_path?.localeCompare(b.org_unit_path));
            setOrgUnitPath(data);
            setAllOrgUnitPath(data);
        } catch (e) {
            console.error(e);
        }
    }, []);

    return (
        <OrgUnitPathsContext.Provider value={{ fetchOrgUnitPaths, orgUnitPath, allOrgUnitPath, setOrgUnitPath }}>
            {children}
        </OrgUnitPathsContext.Provider>
    );
}
