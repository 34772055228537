import React from 'react'
import EmployeesList from '../components/EmployeesList.jsx'
import { useNavigate } from 'react-router-dom';
const EtroTeamPage = () => {
    const navigate = useNavigate()
    const slackToken = localStorage.getItem('slackUserData')
    const googleToken = localStorage.getItem('user')

    if (!googleToken || !slackToken) {
        navigate('/')
    }

    return (
        <React.Fragment>
            <EmployeesList />
        </React.Fragment>
    )
}

export default EtroTeamPage