import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';
import { Avatar, ImageList, ImageListItem, Stack, Button } from '@mui/material';
import styles from '../css/Navbar.module.css'
import NotAuth from './NotAuth.jsx';
import MenuSettings from './MenuSettings.jsx'
import MenuPeopleBuilder from './MenuPeopleBuilder.jsx';
import BackdropLoader from './common/BackdropLoader.jsx'
import api from '../apis/api.js';
import { SnackbarContext } from '../context/SnackbarContext.js';

const Navbar = () => {
    const [userLogged, setUserLogged] = useState()
    const user = localStorage.getItem('user')
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const { openAlert } = useContext(SnackbarContext)
    useEffect(() => {

        if (user != 'undefined') {
            setUserLogged(JSON.parse(user))
        } else {
            // return <NotAuth />
        }

    }, [])

    const copyGoals = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.post('/copy-goals')
            openAlert('Done! Refresh page', 'success')
            setOpenBackdrop(false)
        } catch (error) {
            openAlert('Error', 'error')
            console.log(error)
        }
    }

    return (
        <AppBar style={{ height: '4rem' }} position="fixed"
            sx={{ backgroundColor: '#2B2B2B' }}>
            <Toolbar>
                <ImageList sx={{ width: '30rem' }} >
                    <ImageListItem>
                        <img src="https://images.squarespace-cdn.com/content/v1/562af2bee4b094a1579b7245/1510334525983-3D9G5SP3INR4JF4YRGCO/etro_white.png?format=1500w" />
                    </ImageListItem>
                </ImageList>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}

                <Stack direction='row' className={styles.photo_name}>
                    <Stack direction='row' spacing={2} alignItems='flex-start'>
                        <Button color="inherit" component={Link} to="/employee">ETRO Team</Button>
                        {/* <Button color="inherit" component={Link} to="/users/datagrid">DataGrid</Button> */}
                        <MenuPeopleBuilder />
                        <MenuSettings />
                    </Stack>
                    <Button variant='contained' onClick={copyGoals} >copy goals from previous year</Button>
                    <Stack direction='row' justifyContent="center" alignItems="center" >
                        <Avatar src={userLogged?.photo_url ?? ''} sx={{ width: 40, height: 40, marginRight: 1 }} />
                        <Button color='inherit'>{userLogged?.full_name ?? ''}</Button>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar >

    )
}

export default Navbar;