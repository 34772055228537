import React, {useState} from 'react'
import { Dialog, DialogContent, DialogActions, TextField, Button } from '@mui/material'
import { saveNewDepartmentAPI } from '../apis/departmentsAPI.js';


const NewDepartmentDialog = ({ openAlert, setLoading, setSelectedFields, openDialogNewDepartment, handleCloseDialog, getDepartments }) => {
    const [newDepartment, setNewDepartment] = useState('')

    const createNewDepartment = async (newDepartment) => {
        handleCloseDialog()
        setLoading(true)
        const response = await saveNewDepartmentAPI(newDepartment)
        await getDepartments()
        openAlert(response.message, response && response.status === 200 ? 'success' : 'error')
        response && response.status === 200 ? setSelectedFields(prev => ({ ...prev, department: newDepartment })) : ''
        setLoading(false)
    }

    return (
        <Dialog
            open={openDialogNewDepartment}
            aria-labelledby="alert-dialog-department"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <TextField
                    label="Enter new Department"
                    onChange={(e) => {
                        setNewDepartment(e.target.value)
                    }}
                />
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                    >Cancel</Button>
                    <Button
                        onClick={() => {
                            createNewDepartment(newDepartment)
                        }}
                        variant="outlined"
                    >Confirm</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default NewDepartmentDialog