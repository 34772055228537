import React, { useEffect, useState } from 'react'
import {
    Container, Card, Stack, Button, CardContent, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, IconButton
} from '@mui/material'
import api from '../apis/api';
import BackdropLoader from './common/BackdropLoader.jsx'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import globalStyles from '../css/GlobalStyles.module.css'
import moment from 'moment';
import NewPeopleBuilderSettings from './NewPeopleBuilderSettings.jsx'
import { SnackbarContext } from '../context/SnackbarContext.js'

const PeopleBuilderSettings = () => {
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [peopleBuilderSettings, setPeopleBuilderSettings] = useState([])
    const [peopleBuilderId, setPeopleBuilderId] = useState()
    const [editingRowId, setEditingRowId] = useState('')
    const [focusRow, setFocusRow] = useState(null)
    const [openPeopleBuilderForm, setOpenPeopleBuilderForm] = useState(false)
    const { openAlert } = React.useContext(SnackbarContext)
    const [months] = useState([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ])

    const [pbSettingsSelectedToDelete, setPbSettingsSelectedToDelete] = useState({
        start_date: '',
        id: ''
    })
    const [openDeletePbSettings, setOpenDeletePbSettings] = useState(false)

    const handleClose = () => {
        setOpenPeopleBuilderForm(false)
    }

    const getPeopleBuilderSettings = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.get('/people-builder-settings')
            const values = response.data.filter(value => value.status !== 'archived')
            setPeopleBuilderSettings(values)
            setOpenBackdrop(false)
        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
        }
    }

    const deletePbSettings = async () => {

        setOpenBackdrop(true)
        setOpenDeletePbSettings(false)
        const id = pbSettingsSelectedToDelete.id

        try {
            const response = await api.delete(`/people-builder-settings/${id}`)
            console.log(response)
            openAlert(response.data.message, 'success')
            getPeopleBuilderSettings()
            setOpenBackdrop(false)

        } catch (error) {
            openAlert('Error on delete People Builder Settings', 'error')
            setOpenBackdrop(false)
            console.log('Error on delete People Builder Settings', error)
        }
    }

    useEffect(() => {
        getPeopleBuilderSettings()
    }, [])

    return (
        <Container sx={{ mt: 10, }} maxWidth='xl'>
            <BackdropLoader openBackdrop={openBackdrop} />
            <Card elevation={2}>
                <CardContent sx={{ overflowY: 'auto', maxHeight: 'calc(95vh - 64px)' }}>
                    <Stack direction="row" alignItems="center" >
                        <Button
                            onClick={() => {
                                setOpenPeopleBuilderForm(true)
                                setPeopleBuilderId(null)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >
                            Add New
                        </Button>
                    </Stack>
                    <TableContainer sx={{ boxShadow: 3 }} className={globalStyles.tableContainer}>
                        <Table stickyHeader className={globalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Year</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc', width: 130 }}>Start Date</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc', width: 130 }}>End Date</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc', width: 160 }}>Mid-Year Check-In</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc', width: 160 }}>End-Year Check-In</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Status</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Goals Settings File</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={globalStyles.cursor_pointer}>
                                {peopleBuilderSettings.map((pbSettings) => (
                                    <TableRow
                                        hover
                                        key={pbSettings.id}
                                        onMouseEnter={() => setFocusRow(pbSettings.id)}
                                        onMouseLeave={() => setFocusRow(null)}
                                        sx={{ height: '10vh' }}
                                    >
                                        <TableCell>{new Date(pbSettings.start_date).getUTCFullYear()}</TableCell>
                                        <TableCell>{pbSettings.start_date?.split('T')[0]}</TableCell>
                                        <TableCell>{pbSettings.end_date?.split('T')[0]}</TableCell>
                                        <TableCell>{
                                            months[moment(
                                                new Date(pbSettings.mid_year_checkin).toISOString().split('T')[0]).month()]
                                        }
                                        </TableCell>
                                        <TableCell>{
                                            months[moment(
                                                new Date(pbSettings.end_year_checkin).toISOString().split('T')[0]).month()]
                                        }
                                        </TableCell>
                                        <TableCell>{pbSettings.status}</TableCell>
                                        <TableCell>{pbSettings.file_url}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>
                                            {(editingRowId !== pbSettings.id) && (
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setPeopleBuilderId(pbSettings.id)
                                                            setOpenPeopleBuilderForm(true)
                                                        }}
                                                        size='small'>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenDeletePbSettings(true)
                                                            setPbSettingsSelectedToDelete({
                                                                start_date: pbSettings.start_date?.split('T')[0],
                                                                id: pbSettings.id
                                                            })
                                                        }}
                                                        size='small'>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                            {/* {editingRowId === pbSettings.id && (
                                                <Fade in={editingRowId === pbSettings.id} timeout={500}>
                                                    <Stack direction='row'>
                                                        <Fab size="small">
                                                            <SaveIcon onClick={() => {
                                                                updatePeopleBuilderSettings(pbSettings.id, pbData)
                                                                setEditingRowId(null)
                                                            }} />
                                                        </Fab>
                                                    </Stack>
                                                </Fade>
                                            )} */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openPeopleBuilderForm}
                onClose={handleClose}
                maxWidth='1'
            >
                <DialogContent>
                    <NewPeopleBuilderSettings
                        peopleBuilderSettings={peopleBuilderSettings}
                        getPeopleBuilderSettings={getPeopleBuilderSettings}
                        setPeopleBuilderId={setPeopleBuilderId}
                        peopleBuilderId={peopleBuilderId}
                        setOpenPeopleBuilderForm={setOpenPeopleBuilderForm}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeletePbSettings}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {pbSettingsSelectedToDelete.start_date}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeletePbSettings(false)}>Cancel</Button>
                    <Button onClick={deletePbSettings} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container >
    )
}

export default PeopleBuilderSettings