import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import { SnackbarContext } from '../context/SnackbarContext.js';

const SnackbarAlert = () => {
    const { openSnackbar, severytypeSnackbar, messageSnackbar, handleClose } = useContext(SnackbarContext)

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={severytypeSnackbar}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {messageSnackbar}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlert;