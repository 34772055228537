import api from "./api.js";

export const sendEmailsAddittionalInfoToPlayer = async (data) => {
    let content = `
                <div>
                    <span>Hello ${data.player.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Now that all of <b>your</b> goals for  ${data.year} are active, you will need to provide some additional information. </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/personal-people-builder'}/${data.player.id}">ETRO Lifecycle</a> at your earliest convenience to fill out the <b>Additional Information</b> form. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                `
    const emailObject = {
        to: data.player.email,
        subject: `Additional information regarding ${data.player.first_name}’s ${data.year} goals is requested`,
        html: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendEmailsAddittionalInfoToCoach = async (data) => {
    let content = `
                <div>
                    <span>Hello ${data.player.coach} </span>
                </div>
                <br>
                <div>
                    <span>Now that all of <b>${data.player.full_name}</b>  ’s goals for  ${data.year} are active, you will both need to provide some additional information. </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${data.coach.id}">ETRO Lifecycle</a> at your earliest convenience to fill out the <b>Additional Information</b> form. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                `
    const emailObject = {
        to: data.coach.email,
        subject: `Additional information regarding ${data.player.first_name}’s ${data.year} goals is requested`,
        html: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendEmailsGoalReadyForApproval = async (emailData) => {
    const { user } = emailData

    let content = `
                <div>
                    <span>Hello ${user.player.coach} </span>
                </div>
                <br>
                <div>
                    <span>Your player <b>${user.player.full_name}</b>  has submitted the ${emailData.goal_term} goal <b>${emailData.goal_name}</b> for your approval! </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${user.coach.id}">ETRO Lifecycle</a> at your earliest convenience to evaluate this goal. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                `
    const emailObject = {
        to: user.coach.email,
        subject: `${user.player.first_name}’s ${emailData.goal_term} goal is ready for review`,
        html: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendEmailsGoalNeedsRevision = async (emailData) => {
    const { user } = emailData

    let content = `
                <div>
                    <span>Hello ${user.player.first_name} </span>
                </div>
                <br>
                <div>
                    <span>Your coach ${user.coach.first_name} has flagged the following goal(s) for revision:</span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                        <a href="${'https://dev-lifecycle.etroconstruction.dev/personal-people-builder'}/${user.player.id}">ETRO Lifecycle</a> 
                        at your earliest convenience to see your coach's comments and revise these goals accordingly. 
                    </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                `
    const emailObject = {
        to: user.player.email,
        subject: `${user.player.first_name}’s ${emailData.goal_term} goal is ready for review`,
        html: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}

export const readyForApproval = async (emailData) => {
    let content = `
                <div>
                    <span>Hello ${emailData.player.coach} </span>
                </div>
                <br>
                <div>
                    <span>Your player <b>${emailData.player.full_name}</b> has submitted goals for your approval! </span>
                    <br>
                    <br>
                    <span> Please visit the PeopleBuilder portal in 
                    <a href="${'https://dev-lifecycle.etroconstruction.dev/team-people-builder'}/${emailData.coach.id}">ETRO Lifecycle</a> at your earliest convenience to evaluate this goal. </span>
                </div>
                <br>
                <br>
                <i> If you have any issues accessing the portal, please <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@etroconstruction.zohodesk.com">Contact IT</a>
                </i>
                `
    const emailObject = {
        to: emailData.coach.email,
        subject: `${emailData.player.first_name}’s goal is ready for review`,
        html: content
    }

    try {
        return await api.post('/send-emails', emailObject)
    } catch (error) {
        console.log(error)
        return error
    }
}