import React, { useEffect } from 'react'
import { Button, FormGroup, Paper, Stack, Switch } from '@mui/material'

const GoalStatus = ({ leftStatus, rightStatus, setChecked, checked, goalDisabled, updateGoalData }) => {

    useEffect(() => {

        updateGoalData('goal_approval', checked)
        if (checked) {
            if (rightStatus === 'Goal Approved') {
                updateGoalData('goal_status', 'Active')
            } else if (rightStatus === 'Ready for Approval') {
                updateGoalData('goal_status', 'Approval')
            }
        } else {
            if (leftStatus === 'Draft Goal') {
                updateGoalData('goal_status', 'Draft Goal')
            } else if (leftStatus === 'Back To Revision') {
                updateGoalData('goal_status', 'Needs Revision')
            }
        }
    }, [checked])

    return (
        <Paper
            id='draft-goal-and-approval'
            sx={{ padding: 2 }}
            elevation={4}
        >
            <FormGroup sx={{ alignContent: 'center' }}>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Button
                        sx={{ backgroundColor: !checked ? 'orange' : 'grey', }}
                        disabled={goalDisabled}
                        variant="contained"
                        onClick={() => setChecked(false)}
                    >
                        {leftStatus}
                    </Button>
                    <Switch
                        color="default"
                        disabled={goalDisabled}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: checked ? 'green' : 'grey' }}
                        disabled={goalDisabled}
                        onClick={() => setChecked(true)}
                    >
                        {rightStatus}
                    </Button>
                </Stack>
            </FormGroup>
        </Paper>
    )

}

export default GoalStatus;