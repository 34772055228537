import api from "./api"

export const deleteGoalAPI = async (id) => {

    try {
        const response = await api.delete(`/goals/${id}`)
        return response.data
    } catch (error) {
        console.log(error)
        return { message: 'Something was wrong', type: 'error', error: error, status: 401 }
    }
}

export const getGoalsAPI = async () => {

    try {
        const response = await api.get(`/goals`)
        return response.data
    } catch (error) {
        console.log(error)
        return { message: 'Something was wrong', type: 'error', error: error, status: 401 }

    }
}

export const createGoalAPI = async (goal) => {

    try {
        return await api.post('/goals', goal)
    } catch (error) {
        console.log(error)
        return { message: 'Something was wrong', type: 'error', error: error, status: 401 }
    }
}

export const updateGoalAPI = async (id, goal) => {

    try {
        return await api.post(`/goals/${id}`, goal)
    } catch (error) {
        console.log(error)
        return { message: 'Something was wrong', type: 'error', error: error, status: 401 }
    }
}