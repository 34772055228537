import React, { useState, createContext } from 'react'
import api from '../apis/api.js'

export const CheckInContext = createContext()

export const CheckInProvider = ({ children }) => {

    const [checkIns, setCheckIns] = useState()

    const getCheckIns = async () => {

        try {
            const response = await api.get('/checkins')
            setCheckIns(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <CheckInContext.Provider value={{ checkIns, getCheckIns }} >
            {children}
        </CheckInContext.Provider>
    )
}