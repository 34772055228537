import React from 'react'
import Departments from '../components/Departments'

const DepartmentsPage = () => {
    return (
        <React.Fragment>
            <Departments />
        </React.Fragment>
    )
}

export default DepartmentsPage