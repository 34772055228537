// FirstGoal.jsx
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Container, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackdropLoader from './common/BackdropLoader.jsx'
import api from '../apis/api.js'

const FirstGoalPDF = ({ peopleBuilder }) => {
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [url, setUrl] = useState('')
    // const googleDriveUrl = 'https://drive.google.com/file/d/1Q8nnBcQYP5mRt92d5gto4FS-9ou-YWYG/preview';
    const navigate = useNavigate()

    const getUserPeopleBuilderSettings = async () => {

        try {
            const response = await api.get('/people-builder-settings')
            const values = response.data.filter(value => value.status !== 'archived' && new Date(value.start_date).getUTCFullYear() === new Date().getUTCFullYear())

            if (values.length) {
                setUrl(values[0].file_url)
            }

            setOpenBackdrop(false)

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
        }
    }

    const openGoalForm = () => {
        navigate(`/goal-form/people-builder/${peopleBuilder.id}`)
    }

    const handleIframeLoad = () => {
        setOpenBackdrop(false)
    }

    useEffect(() => {
        getUserPeopleBuilderSettings()
    }, [])

    return (
        <Container>
            {openBackdrop && (
                <BackdropLoader openBackdrop={openBackdrop} />
            )}
            <Card sx={{ mt: 2, height: '85vh' }}>
                <Box sx={{ backgroundColor: 'grey' }}>
                    <Typography
                        sx={{ textAlign: 'center', p: 1 }}
                        variant='h5'
                    >
                        People Builder Goals Setting
                    </Typography>
                </Box>
                <CardContent>
                    <Paper sx={{ height: '70vh' }} elevation={2}>
                        <iframe style={{
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available'
                        }}
                            src={url}
                            onLoad={handleIframeLoad}
                        />
                    </Paper>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={openGoalForm}
                        sx={{ m: 'auto' }}
                        variant='contained'
                    >
                        start your people builder
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default FirstGoalPDF;